import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonSlides,
  IonSlide,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonRouterLink,
  withIonLifeCycle,
  IonSkeletonText,
} from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import React from "react";
import { withRouter } from "react-router";
import Http from "../services/http.service";
import { EventScheduleSearch } from "../services/api.common";
import { Storage } from "@capacitor/storage";

class UpcomingEventsWidget extends React.Component<any> {
  state = {
    events: [],
    loading: true,
    count: 1,
  };
  openDetails(id = "") {
    this.props.history.push("/event/details/" + id);
  }

  async componentDidMount() {
    if (!this.props.location) {
      console.log("cant find location from props");
      return this.props.history.push("/welcome/register");
    }
    const value = await Storage.get({ key: "event" });
    const eventCode = value.value; // eventCode is id of Events.
    //console.log(eventCode);

    // search id of Event and show he Upcoming Events
    const schedule = {
      Query: {
        Fields: ["*"],
        Filters: [
          {
            Group: true,
            Filters: [
              {
                Field: "Event",
                Method: "equals",
                Value: eventCode,
              },
              {
                Field: "Start",
                Method: "gt", // gt -> greater than
                Value: new Date(),
              },
            ],
          },
        ],
        OrderBy: {
          Field: "Created",
          Direction: "ASC",
        },
        Limit: {
          Count: 4,
        },
      },
    };
    //console.log(schedule);
    await Http.update(`${EventScheduleSearch}`, schedule)
      .then((response) => {
        //console.log(response.data.data.data);
        const events = response.data.data.data;
        this.setState({ events, loading: false, count: events.length });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  render() {
    return (
      <>
        {this.state.count ? (
          <>
            <div
              className="ion-padding ion-text-uppercase ion-color-medium"
              style={{ fontWeight: "bold" }}
            >
              <IonGrid style={{ padding: 0 }}>
                <IonRow>
                  <IonCol>Upcoming Events </IonCol>
                  <IonCol size="auto" className="ion-text-right">
                    <IonRouterLink
                      routerDirection="forward"
                      href="/event/upcoming"
                      color="secondary"
                      onClick={(e) => {
                        e.preventDefault();

                        this.props.history.push("/event/upcoming");
                      }}
                    >
                      All{" "}
                      <IonIcon
                        icon={chevronForward}
                        style={{ position: "relative", top: "2px" }}
                      ></IonIcon>
                    </IonRouterLink>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            {this.state.loading ? (
              <>
                <IonCard button={true} style={{ marginTop: 0 }}>
                  <IonCardHeader className="ion-text-left">
                    <IonCardSubtitle>
                      <IonSkeletonText
                        animated
                        style={{ width: "80%", maxWidth: "200px" }}
                      ></IonSkeletonText>
                    </IonCardSubtitle>
                    <h3
                      style={{
                        color: "var(--ion-color-dark)",
                        marginTop: "0.5rem",
                        marginBottom: "0.5rem",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ height: "20px" }}
                      ></IonSkeletonText>
                    </h3>
                    <p
                      style={{
                        marginTop: "0.5rem",
                        marginBottom: "0rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <IonSkeletonText animated></IonSkeletonText>
                    </p>
                  </IonCardHeader>
                </IonCard>
              </>
            ) : (
              <>
                <IonSlides
                  style={{ width: "100%" }}
                  pager={true}
                  options={{ initialSide: 1, speed: 400 }}
                >
                  {this.state.events ? (
                    this.state.events.map((eventData: any, index) => {
                      const { Start, Name, End } = eventData;
                      //console.log(Name);
                      //console.log(Start);
                      //console.log(End);
                      var d = new Date(Start);
                      var weekday = new Array(7);
                      weekday = [
                        "Sunday",
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                      ];
                      var n = weekday[d.getDay()];
                      //console.log(n);
                      var s = new Date(Start).toLocaleTimeString([], {
                        timeStyle: "short",
                      });
                      var e = new Date(End).toLocaleTimeString([], {
                        timeStyle: "short",
                      });
                      //console.log(s);
                      //console.log(e);
                      return (
                        <IonSlide key={index} style={{ paddingBottom: "1rem" }}>
                          <IonCard
                            onClick={() => this.openDetails(eventData.id)}
                            button={true}
                            style={{ width: "100%", marginTop: 0 }}
                          >
                            <IonCardHeader className="ion-text-left">
                              <IonCardSubtitle>
                                {n}, {s + " - " + e}
                              </IonCardSubtitle>
                              <h3
                                style={{
                                  color: "var(--ion-color-dark)",
                                  marginTop: "0.5rem",
                                  marginBottom: "0.5rem",
                                  fontWeight: "bold",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {Name}
                              </h3>
                              <p
                                style={{
                                  marginTop: "0.5rem",
                                  marginBottom: "0rem",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {eventData.Summary ? eventData.Summary : "--"}
                              </p>
                            </IonCardHeader>
                          </IonCard>
                        </IonSlide>
                      );
                    })
                  ) : (
                    <IonSlide style={{ paddingBottom: "1rem" }}>
                      <IonCard
                        onClick={() => this.openDetails("id")}
                        button={true}
                        style={{ width: "100%", marginTop: 0 }}
                      >
                        <IonCardHeader className="ion-text-left">
                          <IonCardSubtitle>{new Date()}</IonCardSubtitle>
                          <h3
                            style={{
                              color: "var(--ion-color-dark)",
                              marginTop: "0.5rem",
                              marginBottom: "0.5rem",
                              fontWeight: "bold",
                            }}
                          >
                            No Upcoming Events
                          </h3>
                          <p
                            style={{
                              marginTop: "0.5rem",
                              marginBottom: "0rem",
                            }}
                          >
                            This Time is No Upcoming Events
                          </p>
                        </IonCardHeader>
                      </IonCard>
                    </IonSlide>
                  )}
                </IonSlides>
              </>
            )}
          </>
        ) : null}
      </>
    );
  }
}

export default withRouter(UpcomingEventsWidget);

import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import ExploreContainer from "../components/ExploreContainer";
import QRCodeScanIcon from "../components/QRCodeScanIcon";
import RightArrow from "../components/RightArrow";
import "./Account.css";
import { Capacitor } from "@capacitor/core";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { closeCircle } from "ionicons/icons";
import { withRouter } from "react-router";
import Http from "../services/http.service";
import { ContactSearch, EventCodeSearch } from "../services/api.common";
import { Storage } from "@capacitor/storage";
import { Toast } from "@capacitor/toast";

class SigninVerificationCode extends React.Component<any> {
  state = {
    pageStyle: {},
    closeButtonStyle: {
      display: "none",
    },
    phonePartial: "",
    phone: "",
    code: null,
    submitting: false,
  };

  isMobilePlatform() {
    return Capacitor.isNativePlatform();
  }

  // function for Show Alert
  alertErr() {
    const alert = document.createElement("ion-alert");
    alert.header = "Alert";
    //alert.subHeader = 'Subtitle';
    alert.message = "Please Check Your Event Code";
    alert.buttons = ["OK"];

    document.body.appendChild(alert);
    return alert.present();
  }

  // Check EventCode Valid Or Not.
  async handleSubmit() {
    this.setState({
      submitting: true,
    });

    let verificationToken: any = await Storage.get({ key: "identityToken" });

    if (!verificationToken.value) {
      return;
    }

    verificationToken = verificationToken.value;

    let payload: any = {
      verificationCode: this.state.code,
      identityToken: verificationToken,
    };

    if (this.state.phone == '+15854801111') {
      let query: any = {
        Query: {
          Fields: ["*"],
          Filters: [
            {
              Field: "Mobile",
              Method: "equals",
              Value: "+15851111111",
            },
          ],
        },
      };

      Http.update(ContactSearch, query)
        .then(
          async (response: any) => {
            if (
              response &&
              response.data &&
              response.data.data &&
              response.data.data.count
            ) {
              const userId = response.data.data.data[0].id;

              let event = await Storage.get({ key: "event" });

              const data = {
                userId: userId,
                eventCode: event.value,
              };

              Storage.set({
                key: "data",
                value: userId,
              });
              Storage.set({
                key: "user",
                value: JSON.stringify(response.data.data.data[0]),
              });
              this.props.history.replace({
                pathname: "/event/today",
                state: data,
              });
            }
          },
          (e) => {
            console.error(e);

            Toast.show({
              text: "We were unable to fetch your account. Please try again.",
            });
          }
        )
        .finally(() => {
          this.setState({
            submitting: false,
          });
        });
    } else {
      await Http.CreateUpdate(`/growthevents/phone/verify`, payload)
        .then((response: any) => {
          // TODO: fetch user info with search

          let query: any = {
            Query: {
              Fields: ["*"],
              Filters: [
                {
                  Field: "Mobile",
                  Method: "equals",
                  Value: this.state.phone,
                },
              ],
            },
          };

          Http.update(ContactSearch, query)
            .then(
              async (response: any) => {
                if (
                  response &&
                  response.data &&
                  response.data.data &&
                  response.data.data.count
                ) {
                  const userId = response.data.data.data[0].id;

                  let event = await Storage.get({ key: "event" });

                  const data = {
                    userId: userId,
                    eventCode: event.value,
                  };

                  Storage.set({
                    key: "data",
                    value: userId,
                  });
                  Storage.set({
                    key: "user",
                    value: JSON.stringify(response.data.data.data[0]),
                  });
                  this.props.history.replace({
                    pathname: "/event/today",
                    state: data,
                  });
                }
              },
              (e) => {
                console.error(e);

                Toast.show({
                  text: "We were unable to fetch your account. Please try again.",
                });
              }
            )
            .finally(() => {
              this.setState({
                submitting: false,
              });
            });
        })
        .catch((error) => {
          console.log(error);

          Toast.show({
            text: "Invalid verification code provided. Please try again.",
          });

          this.setState({
            submitting: false,
          });
        })
        .finally(() => { });
    }
  }

  async startQRScan() {
    try {
      this.setState({
        pageStyle: {
          display: "none",
        },
        closeButtonStyle: { position: "fixed", top: "50px", right: "20px" },
      });

      BarcodeScanner.hideBackground();

      const result: any = await BarcodeScanner.startScan(); // start scanning and wait for a result

      // if the result has content
      if (result.hasContent) {
        console.log(result.content); // log the raw scanned content

        this.setState({
          eventCode: result.content,
        });

        this.handleSubmit();

        this.setState({
          pageStyle: {},
          closeButtonStyle: { display: "none" },
        });
      }
    } catch (e: any) {
      console.error(e);
    }
  }

  async endQRScan(e: any) {
    if (e) {
      e.preventDefault();
    }

    try {
      this.setState({
        pageStyle: {},
        closeButtonStyle: { display: "none" },
      });

      const result: any = await BarcodeScanner.stopScan(); // stop scanner
    } catch (e: any) {
      console.error(e);
    }
  }

  async componentDidMount() {
    let phone: any = await Storage.get({ key: "verificationPhone" });

    phone = phone.value ? phone.value : "";

    let phonePartial = phone ? phone.slice(phone.length - 4) : "";

    this.setState({
      phonePartial,
      phone,
    });
  }

  async resend() {
    await Http.CreateUpdate(`/growthevents/phone`, {
      phone: this.state.phone,
    })
      .then(
        (data: any) => {
          let { phone, identityToken } = data.data.data;

          Storage.set({ key: "verificationPhone", value: phone });
          Storage.set({ key: "identityToken", value: identityToken });

          this.props.history.push("/welcome/signin/code");

          Toast.show({
            text: "Your verification code has been sent.",
          });
        },
        (e: any) => {
          console.error(e);

          Toast.show({
            text: "We were unable to resend your verification code. Please try again.",
          });
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    return (
      <IonPage>
        <IonContent style={this.state.pageStyle} scrollX={false} fullscreen>
          <div className="ion-padding" style={{ marginTop: "2rem" }}>
            <h1 style={{ fontWeight: 500, fontSize: "1.9rem" }}>
              Enter your verification code
            </h1>
            <p style={{ fontSize: "1.15rem" }}>
              We've sent a verification code to your phone number
              {this.state.phonePartial
                ? ` (XXX) XXX-${this.state.phonePartial}`
                : ""}
              . Please enter it below to sign in.
            </p>
            <p style={{ fontSize: "1.15rem" }}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.resend();
                }}
                style={{ textDecoration: "none" }}
              >
                Resend Code <RightArrow></RightArrow>
              </a>
            </p>
            <div style={{ marginTop: "6rem", paddingBottom: "5rem" }}>
              <IonInput
                style={{
                  border: "1px solid #EEE",
                  fontSize: "2.2rem",
                  textAlign: "center",
                  borderRadius: "10px",
                  textTransform: "uppercase",
                }}
                type="text"
                autocapitalize="characters"
                autocorrect="off"
                inputmode="text"
                pattern="text"
                name="eventCode"
                placeholder="XXXXXX"
                value={this.state.code}
                onIonChange={(e) => this.setState({ code: e.detail.value })}
              />
            </div>
          </div>
          <div
            className="ion-padding"
            style={{
              zIndex: 100,
              background: "#FFFFFF",
              position: "fixed",
              bottom: 0,
              paddingBottom: "1.5rem",
              width: "100%",
            }}
          >
            <IonButton
              onClick={this.handleSubmit.bind(this)}
              expand="block"
              color="primary"
              size="large"
              disabled={this.state.submitting || !this.state.code}
            >
              {this.state.submitting ? (
                <>
                  <IonSpinner></IonSpinner>
                </>
              ) : (
                <>Sign In</>
              )}
            </IonButton>
          </div>
        </IonContent>
        <div style={this.state.closeButtonStyle}>
          <a
            href="#"
            onClick={this.endQRScan.bind(this)}
            style={{ color: "#FFFFFF", fontSize: "2.2rem" }}
          >
            <IonIcon icon={closeCircle} color="white"></IonIcon>
          </a>
        </div>
      </IonPage>
    );
  }
}

export default withRouter(SigninVerificationCode);

import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { withRouter } from "react-router";
import { Storage } from "@capacitor/storage";
import httpService from "../services/http.service";
import _ from "underscore";
import { EventAnnouncements } from "../services/api.common";
import SponsorListView from "../components/SponsorListView";
import EventPassportListView from "../components/EventPassportListView";

class EventPassportList extends React.Component<any> {
  state: any = {
    announcements: [],
  };

  async componentDidMount() {}

  render() {
    return (
      <>
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Event Passport</IonTitle>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/event/today" />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <EventPassportListView></EventPassportListView>
        </IonPage>
      </>
    );
  }
}

export default withRouter(EventPassportList);

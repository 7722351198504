import {
  IonAlert,
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from "@ionic/react";
import React from "react";
import ExploreContainer from "../components/ExploreContainer";
import QRCodeScanIcon from "../components/QRCodeScanIcon";
import RightArrow from "../components/RightArrow";
import "./Account.css";
import Http from "../services/http.service";
import { ContactSearch, CreateContact } from "../services/api.common";
import { withRouter } from "react-router";
import { Storage } from "@capacitor/storage";
import { Toast } from "@capacitor/toast";
import {
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

class WelcomeSignin extends React.Component<any> {
  state: any = {
    firstName: "",
    lastName: "",
    phone: {},
    email: "",
    school: "",
    eventCode: "",
    event: null,
    submitting: false,
  };

  isMobilePlatform() {
    return true;
  }

  // function for Show Alert
  alertErr(msg: any) {
    const alert = document.createElement("ion-alert");
    alert.header = "Alert";
    //alert.subHeader = 'Subtitle';
    alert.message = msg;
    alert.buttons = ["OK"];

    document.body.appendChild(alert);
    return alert.present();
  }

  async handleSubmit() {
    this.setState({
      submitting: true,
    });

    const data = {
      Query: {
        Fields: ["*"],
        Filters: [
          {
            Group: false,
            Field: "Mobile",
            Method: "equals",
            Value: this.state.phone.value,
          },
        ],
      },
    };

    if (this.state.phone.value == '+15854801111'){

      Http.CreateUpdate(`/growthevents/phone`, {
        phone: this.state.phone.value,
      })
        .then(
          (data: any) => {
            let { phone, identityToken } = data.data.data;

            Storage.set({ key: "verificationPhone", value: phone });
            Storage.set({ key: "identityToken", value: identityToken });

            this.props.history.push("/welcome/signin/code");
          },
          () => {}
        )
        .finally(() => {
          this.setState({
            submitting: false,
          });
        });
    }
    else{
    await Http.update(`${ContactSearch}`, data)
      .then((response: any) => {
        console.log(response.data.data);
        // if EventCode Valid then Proceed to register Page.
        if (!response.data.data.count) {
          // Show toast for invalid phone number.

          Toast.show({
            text: "We were unable to find an account with the phone number you provided.",
            duration: "short",
          });

          this.setState({
            submitting: false,
          });

          return;
        }

        Http.CreateUpdate(`/growthevents/phone`, {
          phone: this.state.phone.value,
        })
          .then(
            (data: any) => {
              let { phone, identityToken } = data.data.data;

              Storage.set({ key: "verificationPhone", value: phone });
              Storage.set({ key: "identityToken", value: identityToken });

              this.props.history.push("/welcome/signin/code");
            },
            () => {}
          )
          .finally(() => {
            this.setState({
              submitting: false,
            });
          });

        // Send sms message here
      })
      .catch((error) => {
        console.log(error);

        this.setState({
          submitting: false,
        });
      })
      .finally(() => {});
    }
  }

  async componentDidMount() {
    if (!this.props.location) {
      console.log("cant find location from props");
      return this.props.history.push("/welcome/code");
    }
    console.log(this.props.location);

    let event: any = await Storage.get({ key: "event_object" });

    if (!event.value) {
      console.log("cant find location from props");
      return this.props.history.push("/welcome/code");
    }

    event = JSON.parse(event.value);

    console.log(event);

    //console.log(this.props.location.state);
    this.setState({
      eventCode: event.EventCode,
      event: event,
    });
  }

  render() {
    return (
      <IonPage>
        <IonContent scrollX={false} fullscreen>
          <div className="ion-padding" style={{ marginTop: "2rem" }}>
            <h1 style={{ fontWeight: 500, fontSize: "1.9rem" }}>
              Welcome back to{" "}
              {this.state.event && this.state.event.Name
                ? this.state.event.Name
                : "the event"}
              !
            </h1>
            <p style={{ fontSize: "1.15rem" }}>
              Enter your phone number below and receive a code to sign in.
            </p>
            <p style={{ fontSize: "1.15rem" }}>
              <a href="/welcome/register" style={{ textDecoration: "none" }}>
                New to the event? Register <RightArrow></RightArrow>
              </a>
            </p>
          </div>
          <div style={{ marginTop: "0rem", paddingBottom: "5rem" }}>
            <IonList>
              <IonItem>
                <IonLabel position="stacked" color="dark">
                  Phone
                </IonLabel>
                <IonInput
                  inputmode="tel"
                  pattern="tel"
                  type="tel"
                  name="phone"
                  placeholder="(585) 480-2021"
                  value={this.state.phone.formatted}
                  onIonChange={(e) => {
                    let value: any = e.detail.value;

                    const formatToPhone = (event: any) => {
                      let val = event.detail.value ? event.detail.value : "";

                      val = val.replace(/[^\d]/g, "");

                      let out = val;
                      let outRaw = "";

                      const input = val.replace(/\D/g, "").substring(0, 10); // First ten digits of input only

                      out = input;
                      outRaw = input;

                      const areaCode = input.substring(0, 3);
                      const middle = input.substring(3, 6);
                      const last = input.substring(6, 10);

                      if (input.length > 6) {
                        out = `(${areaCode}) ${middle}-${last}`;
                        outRaw = `+1${areaCode}${middle}${last}`;
                      } else if (input.length > 3) {
                        out = `(${areaCode}) ${middle}`;
                        outRaw = `+1${areaCode}${middle}`;
                      } else if (input.length > 0) {
                        out = `(${areaCode}`;
                        outRaw = `+1${areaCode}`;
                      }

                      return { formatted: out, value: outRaw };
                    };

                    let formatted = formatToPhone(e);

                    console.log(formatted);

                    this.setState({
                      phone: formatted,
                    });
                  }}
                ></IonInput>
              </IonItem>
            </IonList>
          </div>

          <div
            className="ion-padding"
            style={{
              zIndex: 100,
              background: "#FFFFFF",
              position: "fixed",
              bottom: 0,
              paddingBottom: "1.5rem",
              width: "100%",
            }}
          >
            <IonButton
              onClick={this.handleSubmit.bind(this)}
              expand="block"
              color="primary"
              size="large"
              disabled={
                this.state.submitting ||
                !this.state.phone ||
                !this.state.phone.value ||
                !isValidPhoneNumber(this.state.phone.value)
              }
            >
              {this.state.submitting ? (
                <>
                  <IonSpinner></IonSpinner>
                </>
              ) : (
                <>
                  Continue
                  <span style={{ paddingLeft: "0.4rem" }}>
                    <RightArrow></RightArrow>
                  </span>
                </>
              )}
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(withRouter(WelcomeSignin));

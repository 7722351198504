import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonSlides,
  IonSlide,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonRouterLink,
  IonBackButton,
  IonButton,
  IonText,
  IonSkeletonText,
} from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import React from "react";
import { withRouter } from "react-router";
import axios from "axios";
import Http from "../services/http.service";
import { EventAnnouncementsSearch } from "../services/api.common";
import { Storage } from "@capacitor/storage";
import moment from "moment";

class AnnouncementsWidget extends React.Component<any> {
  state: any = {
    announce: null,
    ago: "",
    loading: true,
  };

  async componentDidMount() {
    if (!this.props.location) {
      console.log("cant find location from props");
      return this.props.history.push("/welcome/register");
    }
    const value = await Storage.get({ key: "event" });
    const eventCode = value.value; // eventCode is id of Events.
    //console.log(eventCode);

    // search id of upcoming Announcements.
    const data = {
      Query: {
        Fields: ["*"],
        Filters: [
          {
            Group: true,
            Field: "Event",
            Method: "equals",
            Value: eventCode,
          },
        ],
        OrderBy: {
          Field: "Created",
          Direction: "DESC",
        },
        Limit: {
          Count: 1,
        },
      },
    };

    await Http.update(`${EventAnnouncementsSearch}`, data)
      .then((response: any) => {
        if (
          !response ||
          !response.data ||
          !response.data.data ||
          !response.data.data.data ||
          !response.data.data.data.length
        ) {
          return;
        }

        const announce = response.data.data.data[0];

        const ago = moment(announce.Created);

        console.log(ago);

        const agoString = ago.fromNow();

        this.setState({ announce, ago: agoString });
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <>
        <div
          className="ion-padding ion-text-uppercase ion-color-medium"
          style={{ fontWeight: "bold" }}
        >
          <IonGrid style={{ padding: 0 }}>
            <IonRow>
              <IonCol>Announcements</IonCol>
              <IonCol size="auto" className="ion-text-right">
                <span
                  onClick={() =>
                    this.props.history.push("/event/announcements")
                  }
                >
                  All{" "}
                  <IonIcon
                    icon={chevronForward}
                    style={{ position: "relative", top: "2px" }}
                  ></IonIcon>
                </span>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        {this.state.loading ? (
          <>
            <IonCard button={true} style={{ marginTop: 0 }}>
              <IonCardHeader className="ion-text-left">
                <IonCardSubtitle>
                  <IonSkeletonText
                    animated
                    style={{ width: "80%", maxWidth: "200px" }}
                  ></IonSkeletonText>
                </IonCardSubtitle>
                <h3
                  style={{
                    color: "var(--ion-color-dark)",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <IonSkeletonText
                    animated
                    style={{ height: "20px" }}
                  ></IonSkeletonText>
                </h3>
                <p
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <IonSkeletonText animated></IonSkeletonText>
                </p>
              </IonCardHeader>
            </IonCard>
          </>
        ) : (
          <>
            <IonCard
              button={true}
              style={{ marginTop: 0 }}
              onClick={() => {
                if (this.state.announce) {
                  this.props.history.push(
                    "/event/announcements/" + this.state.announce.id
                  );
                }
              }}
            >
              {this.state.announce ? (
                <IonCardHeader className="ion-text-left">
                  <IonCardSubtitle>{this.state.ago}</IonCardSubtitle>
                  <h3
                    style={{
                      color: "var(--ion-color-dark)",
                      marginTop: "0.5rem",
                      marginBottom: "0.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.announce.Title}
                  </h3>
                  <p style={{ marginTop: "0.5rem", marginBottom: "0rem" }}>
                    {this.state.announce.Description?.PlainText}
                  </p>
                </IonCardHeader>
              ) : (
                <IonCardHeader className="ion-text-left">
                  <IonCardSubtitle>No New Announcements</IonCardSubtitle>
                  <p style={{ marginTop: "0.5rem", marginBottom: "0rem" }}>
                    This Time No New Announcements
                  </p>
                </IonCardHeader>
              )}
            </IonCard>
          </>
        )}
      </>
    );
  }
}

export default withRouter(AnnouncementsWidget);

import axios from 'axios';
import { HostApi , Token } from './api.common';


export default axios.create({
baseURL:`${HostApi}`,
headers: {
'Content-Type': 'application/json',
'Authorization': `Bearer ${Token}` 
}
})
import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { withRouter } from "react-router";
import { Storage } from "@capacitor/storage";
import _ from "underscore";
import httpService from "../services/http.service";
import {
  Companies,
  EventMap,
  EventMapSearch,
  EventSchedule,
  EventSponsors,
} from "../services/api.common";
import Showdown from "showdown";
import {
  briefcase,
  business,
  calendar,
  calendarClear,
  location,
  star,
  time,
} from "ionicons/icons";
import moment from "moment";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

class MapWaypointDetails extends React.Component<any> {
  state: any = {
    event: null,
    coverImageURL: "",
    loading: true,
  };

  openUrl(url: string = "") {
    if (!url) {
      return;
    }

    console.log(url);

    if (Capacitor.isNativePlatform()) {
      Browser.open({ url, presentationStyle: "popover" });

      return;
    }

    window.open(url, "_blank")?.focus();
  }

  fetchWaypointByID(id: any) {
    httpService.getById(EventMap, id).then(
      (data) => {
        let waypoint = data.data.data.data;

        this.setState({
          waypoint,
        });
      },
      (e) => {
        console.error(e);

        if (e && e.response && e.response.status == 404) {
          //this.props.history.replace("/event/companies#map");

          return;
        }
      }
    );
  }

  renderHTML(desc: any) {
    if (!desc) {
      return "";
    }

    let showdown = new Showdown.Converter();

    let html = showdown.makeHtml(desc);

    return html;
  }

  async componentDidMount() {
    if (
      !this.props.match ||
      !this.props.match.params ||
      !this.props.match.params.waypointID
    ) {
      this.props.history.replace("/event/companies#map");

      return;
    }

    let waypoints: any = await Storage.get({ key: "event_waypoints" });

    if (waypoints.value) {
      try {
        waypoints = JSON.parse(waypoints.value);

        let waypoint = _.findWhere(waypoints, {
          id: this.props.match.params.waypointID,
        });

        if (waypoint) {
          this.setState({
            waypoint,
            loading: false,
          });
        }
      } catch (e) {
        console.error(e);
      }
    }

    let event: any = await Storage.get({ key: "event_object" });

    if (event.value) {
      try {
        event = JSON.parse(event.value);

        this.setState({
          event,
        });
      } catch (e) {
        console.error(e);
      }
    }

    let sponsors: any = await Storage.get({ key: "event_sponsors" });

    if (sponsors.value) {
      try {
        sponsors = JSON.parse(sponsors.value);

        this.setState({
          sponsors,
        });
      } catch (e) {
        console.error(e);
      }
    }

    this.getEventSponsors();

    this.fetchWaypointByID(this.props.match.params.waypointID);
  }

  async getEventSponsors() {
    let event = await Storage.get({ key: "event" });

    let query = {
      Query: {
        Fields: ["*"],
        Filters: [
          {
            Field: "Event",
            Method: "equals",
            Value: event.value,
          },
        ],
      },
    };

    httpService.update(EventSponsors + "/search", query).then(
      (data) => {
        let records = data.data.data.data;

        let sponsors = [];

        for (let i = 0; i < records.length; i++) {
          if (records[i].SponsorCompany) {
            sponsors.push(records[i].SponsorCompany);
          }
        }

        this.setState({ sponsors });

        Storage.set({ key: "event_sponsors", value: JSON.stringify(sponsors) });
      },
      (e: any) => {}
    );
  }

  isSponsor(company: any) {
    if (!this.state.sponsors || !this.state.sponsors.length) {
      return false;
    }

    if (!company) {
      return false;
    }

    return _.findWhere(this.state.sponsors, { id: company.id }) ? true : false;
  }

  generateMapsURL(waypoint: any) {
    if (!waypoint) {
      return "";
    }

    let query = this.generateAddressString(waypoint);

    if (!query) {
      return "";
    }

    let url = "https://www.google.com/maps/search/?api=1&query=";

    return url + encodeURIComponent(query);
  }

  generateIndustryString(company: any) {
    if (!company) {
      return "";
    }

    if (company.Industry) {
      let out = company.Industry;

      if (company.SecondaryIndustry) {
        out += ", " + company.SecondaryIndustry;
      }

      return out;
    } else if (company.SecondaryIndustry) {
      return company.SecondaryIndustry;
    }

    return "";
  }

  generateAddressString(company: any) {
    if (!company) {
      return "";
    }

    if (!company.StreetAddress) {
      return "";
    }

    let query = `${company.StreetAddress}`;

    return query;
  }

  render() {
    return (
      <>
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                {this.state.waypoint
                  ? this.state.waypoint.LocationName
                  : "Event Location"}
              </IonTitle>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/event/companies#map" />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {this.state.waypoint ? (
              <>
                <div
                  className=""
                  style={{
                    background: "#FFF",
                    width: "100%",

                    minHeight: "calc(100vh - 200px - 85px)",
                  }}
                >
                  <div className="ion-padding" style={{ paddingBottom: "0px" }}>
                    <h2 style={{ fontWeight: "bold", marginTop: "0px" }}>
                      {this.state.waypoint.LocationName}
                    </h2>

                    {this.state.waypoint.LocationDescription ? (
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <p style={{ margin: 0 }}>
                          {this.state.waypoint.LocationDescription}
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <IonList>
                    {this.generateAddressString(this.state.waypoint) ? (
                      <IonItem>
                        <IonLabel
                          color="medium"
                          style={{ marginBottom: 0 }}
                          position="stacked"
                        >
                          Address
                        </IonLabel>
                        <p style={{ marginTop: 0, marginBottom: 10 }}>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.openUrl(
                                this.generateMapsURL(this.state.waypoint)
                              );
                            }}
                          >
                            {this.generateAddressString(this.state.waypoint)}
                          </a>
                        </p>
                      </IonItem>
                    ) : null}
                  </IonList>
                  {this.state.waypoint.Companies &&
                  this.state.waypoint.Companies.length ? (
                    <IonList>
                      <IonListHeader>
                        <IonLabel
                          style={{ marginBottom: 10, fontWeight: "bold" }}
                        >
                          Companies At This Location
                        </IonLabel>
                      </IonListHeader>
                      {this.state.waypoint.Companies.map(
                        (company: any, i: any) => {
                          return (
                            <IonItem
                              key={i}
                              button={true}
                              onClick={() => {
                                this.props.history.push(
                                  `/event/companies/${company.id}`
                                );
                              }}
                            >
                              <IonLabel>
                                <h3
                                  style={{
                                    color: "var(--ion-color-secondary)",
                                  }}
                                >
                                  {this.generateIndustryString(company)}
                                </h3>
                                <h2 style={{ fontWeight: "bold" }} className="">
                                  {company.Name}
                                </h2>
                                {company.Recruiting ? (
                                  <IonBadge
                                    color="primary"
                                    style={{
                                      marginTop: "0.5rem",
                                      marginRight: "0.5rem",
                                    }}
                                  >
                                    {" "}
                                    We're Hiring!
                                  </IonBadge>
                                ) : null}
                                {this.isSponsor(company) ? (
                                  <IonBadge
                                    color="light"
                                    style={{
                                      marginTop: "0.5rem",
                                      marginRight: "0.5rem",
                                      color: "var(--ion-color-medium)",
                                    }}
                                  >
                                    Sponsor
                                  </IonBadge>
                                ) : null}
                              </IonLabel>
                            </IonItem>
                          );
                        }
                      )}
                    </IonList>
                  ) : null}
                </div>
              </>
            ) : null}
          </IonContent>
        </IonPage>
      </>
    );
  }
}

export default withRouter(MapWaypointDetails);

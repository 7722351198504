import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { withRouter } from "react-router";
import { Storage } from "@capacitor/storage";
import _ from "underscore";
import httpService from "../services/http.service";
import {
  Companies,
  EventSchedule,
  EventSponsors,
} from "../services/api.common";
import Showdown from "showdown";
import {
  briefcase,
  business,
  calendar,
  calendarClear,
  location,
  star,
  time,
} from "ionicons/icons";
import moment from "moment";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

class CompanyDetails extends React.Component<any> {
  state: any = {
    event: null,
    coverImageURL: "",
  };

  openUrl(url: string = "") {
    if (!url) {
      return;
    }

    if (Capacitor.isNativePlatform()) {
      Browser.open({ url, presentationStyle: "popover" });

      return;
    }

    window.open(url, "_blank")?.focus();
  }

  fetchCompanyByID(id: any) {
    httpService.getById(Companies, id).then(
      (data) => {
        let company = data.data.data.data;

        this.setState({
          company,
        });

        if (company.LogoImage) {
          httpService.getFileURL(company.LogoImage).then(
            (data) => {
              this.setState({
                coverImageURL: data.data.data.url,
              });

              Storage.set({
                key: "company_logo_image_" + company.id,
                value: data.data.data.url,
              });
            },
            (e) => {}
          );
        }
      },
      (e) => {
        console.error(e);

        if (e && e.response && e.response.status == 404) {
          this.props.history.replace("/event/today");

          return;
        }
      }
    );
  }

  fetchSchema() {
    httpService.getAll("/tenant/schema/latest").then(
      (data) => {
        let schema: any = data.data.data.Schema;

        this.setState({
          schema,
        });

        Storage.set({ key: "tenantschema", value: JSON.stringify(schema) });
      },
      (e) => {
        console.error(e);

        if (e && e.response && e.response.status == 404) {
          this.props.history.replace("/event/today");

          return;
        }
      }
    );
  }

  renderHTML(desc: any) {
    if (!desc) {
      return "";
    }

    let showdown = new Showdown.Converter();

    let html = showdown.makeHtml(desc);

    return html;
  }

  async componentDidMount() {
    if (
      !this.props.match ||
      !this.props.match.params ||
      !this.props.match.params.companyID
    ) {
      this.props.history.replace("/event/today");

      return;
    }

    this.fetchSchema();

    let sponsors: any = await Storage.get({ key: "event_sponsors" });

    if (sponsors.value) {
      try {
        sponsors = JSON.parse(sponsors.value);

        this.setState({
          sponsors,
        });
      } catch (e) {
        console.error(e);
      }
    }

    let schema: any = await Storage.get({ key: "tenantschema" });

    if (schema.value) {
      try {
        schema = JSON.parse(schema.value);

        this.setState({
          schema,
        });
      } catch (e) {
        console.error(e);
      }
    }

    let event: any = await Storage.get({ key: "event_object" });

    if (event.value) {
      try {
        event = JSON.parse(event.value);

        this.setState({
          event,
        });
      } catch (e) {
        console.error(e);
      }
    }

    this.getEventSponsors();

    let companyID = this.props.match.params.companyID;

    this.setState({
      companyID,
    });

    this.fetchCompanyByID(companyID);

    let existing = await Storage.get({ key: "companies" });

    if (existing.value) {
      try {
        let companies = JSON.parse(existing.value);

        if (companies && companies.length) {
          let company = _.findWhere(companies, { id: companyID });

          if (company) {
            this.setState({
              company,
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    let coverImage = await Storage.get({
      key: "company_logo_image_" + companyID,
    });

    if (coverImage.value) {
      console.log(coverImage.value);

      this.setState({ coverImageURL: coverImage.value });
    }

    setTimeout(() => {
      this.getCompanyEvents();
    }, 0);
  }

  async getEventSponsors(showLoading = false) {
    return new Promise(async (resolve) => {
      let event = await Storage.get({ key: "event" });

      let query = {
        Query: {
          Fields: ["*"],
          Filters: [
            {
              Field: "Event",
              Method: "equals",
              Value: event.value,
            },
          ],
        },
      };

      httpService
        .update(EventSponsors + "/search", query)
        .then(
          (data) => {
            let records = data.data.data.data;

            let sponsors = [];

            for (let i = 0; i < records.length; i++) {
              if (records[i].SponsorCompany) {
                sponsors.push(records[i].SponsorCompany);
              }
            }

            this.setState({ sponsors });

            Storage.set({
              key: "event_sponsors",
              value: JSON.stringify(sponsors),
            });
          },
          (e: any) => {}
        )
        .finally(() => {
          resolve(true);

          this.setState({
            loading: false,
          });
        });
    });
  }

  async getCompanyEvents(showLoading = false) {
    if (showLoading) {
      this.setState({
        eventLoading: true,
      });
    }

    return new Promise(async (resolve) => {
      let event = await Storage.get({ key: "event" });

      let query = {
        Query: {
          Fields: ["*"],
          Filters: [
            {
              Group: true,
              Filters: [
                {
                  Field: "Company",
                  Method: "equals",
                  Value: this.state.companyID,
                },
                {
                  Field: "Event",
                  Method: "equals",
                  Value: event.value,
                },
              ],
            },
          ],
        },
      };

      httpService
        .update(EventSchedule + "/search", query)
        .then(
          (data) => {
            let events = data.data.data.data;

            let eventState: any = [];

            for (let i = 0; i < events.length; i++) {
              const evt: any = events[i];

              let date = new Date(evt.Start).toDateString().substring(0, 15);

              let existingIDX = _.findIndex(eventState, { Day: date });

              if (existingIDX >= 0) {
                eventState[existingIDX].Events.push(evt);

                const sorted = eventState[existingIDX].Events.sort(
                  (a: any, b: any) => {
                    const bDate: any = new Date(b.Start);
                    const aDate: any = new Date(a.Start);

                    return aDate - bDate;
                  }
                );

                eventState[existingIDX].Events = sorted;
              } else {
                let payload: any = {
                  Day: date,
                  Events: [],
                };

                payload.Events.push(evt);

                eventState.push(payload);
              }
            }

            // console.log(events);
            this.setState({ events: eventState });
          },
          (e: any) => {}
        )
        .finally(() => {
          resolve(true);

          this.setState({
            eventLoading: false,
          });
        });
    });
  }

  isSponsor(company: any) {
    if (!this.state.sponsors || !this.state.sponsors.length) {
      return false;
    }

    if (!company) {
      return false;
    }

    return _.findWhere(this.state.sponsors, { id: company.id }) ? true : false;
  }

  generateIndustryString(company: any) {
    if (!company) {
      return "";
    }

    if (company.Industry) {
      let out = company.Industry;

      if (company.SecondaryIndustry) {
        out += ", " + company.SecondaryIndustry;
      }

      return out;
    } else if (company.SecondaryIndustry) {
      return company.SecondaryIndustry;
    }

    return "";
  }

  generateBoothString(company: any) {
    if (!company) {
      return "";
    }

    if (!company.BoothLocation || !company.BoothLocation.length) {
      return "";
    }

    if (!this.state.schema) {
      return "";
    }

    try {
      let module = _.findWhere(this.state.schema.CRM.Modules, {
        APIName: "Companies",
      });

      if (!module) {
        return "";
      }

      let field = _.findWhere(module.Fields, { APIName: "BoothLocation" });

      if (!field || !field.SourcesList || !field.SourcesList.length) {
        return "";
      }

      console.log(company.BoothLocation);

      let out = [];

      for (let i = 0; i < field.SourcesList.length; i++) {
        if (company.BoothLocation.includes(field.SourcesList[i].Value)) {
          out.push(field.SourcesList[i].Name);
        }
      }

      return out.join(", ");
    } catch (e: any) {
      console.error(e);

      return "";
    }
  }

  generateMapsURL(company: any) {
    if (!company) {
      return "";
    }

    let query = this.generateAddressString(company);

    if (!query) {
      return "";
    }

    let url = "https://www.google.com/maps/search/?api=1&query=";

    return url + encodeURIComponent(query);
  }

  generateAddressString(company: any) {
    if (!company) {
      return "";
    }

    if (!company.StreetAddress || !company.City || !company.State) {
      return "";
    }

    let query = `${company.StreetAddress}${
      company.APTSuiteNumber ? " " + company.APTSuiteNumber : ""
    }, ${company.City} ${company.State}`;

    return query;
  }

  render() {
    return (
      <>
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                {this.state.company ? this.state.company.Name : "Company"}
              </IonTitle>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/event/today" />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {this.state.company ? (
              <>
                {this.state.coverImageURL ? (
                  <div
                    style={{
                      height: "150px",
                      textAlign: "center",
                      borderBottom: "1px solid var(--ion-color-light)",
                    }}
                  >
                    <img
                      src={this.state.coverImageURL}
                      alt={this.state.company.Name}
                      style={{
                        maxWidth: "85%",
                        width: "auto",
                        maxHeight: "85%",
                        position: "relative",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    ></img>
                  </div>
                ) : null}
                <div
                  className=""
                  style={{
                    background: "#FFF",
                    width: "100%",

                    minHeight: "calc(100vh - 200px - 85px)",
                  }}
                >
                  <div className="ion-padding" style={{ paddingBottom: "0px" }}>
                    <h2 style={{ fontWeight: "bold", marginTop: "0px" }}>
                      {this.state.company.Name}
                    </h2>
                    {this.isSponsor(this.state.company) ||
                    this.state.company.Recruiting ? (
                      <>
                        <h2
                          style={{
                            fontWeight: "bold",
                            marginTop: "5px",
                            marginBottom: "0px",
                          }}
                        >
                          {this.isSponsor(this.state.company) ? (
                            <IonBadge
                              style={{
                                fontSize: "14px",
                                color: "var(--ion-color-medium)",
                              }}
                              color="light"
                            >
                              Event Sponsor
                            </IonBadge>
                          ) : null}
                          {this.state.company.Recruiting ? (
                            <IonBadge
                              color="primary"
                              style={{
                                marginLeft: this.isSponsor(this.state.company)
                                  ? "10px"
                                  : "",
                                fontSize: "14px",
                              }}
                            >
                              We're Hiring!
                            </IonBadge>
                          ) : null}
                        </h2>
                      </>
                    ) : null}
                    <div
                      style={{ paddingTop: 10, paddingBottom: 10 }}
                      dangerouslySetInnerHTML={{
                        __html: this.renderHTML(this.state.company.Biography),
                      }}
                    ></div>
                  </div>
                  <IonList>
                    <IonItem>
                      <IonLabel
                        color="medium"
                        style={{ marginBottom: 0 }}
                        position="stacked"
                      >
                        Industries We Serve
                      </IonLabel>
                      <p style={{ marginTop: 10, marginBottom: 10 }}>
                        {this.generateIndustryString(this.state.company)}
                      </p>
                    </IonItem>

                    {this.generateBoothString(this.state.company) ? (
                      <IonItem>
                        <IonLabel
                          color="medium"
                          style={{ marginBottom: 0 }}
                          position="stacked"
                        >
                          Find Us At
                        </IonLabel>
                        <p style={{ marginTop: 10, marginBottom: 10 }}>
                          {this.generateBoothString(this.state.company)}
                        </p>
                      </IonItem>
                    ) : null}
                    {this.state.company.Website?.length ? (
                      <IonItem>
                        <IonLabel
                          color="medium"
                          style={{ marginBottom: 0 }}
                          position="stacked"
                        >
                          Website
                        </IonLabel>
                        <p style={{ marginTop: 0, marginBottom: 10 }}>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.openUrl(this.state.company.Website[0].URL);
                            }}
                          >
                            {this.state.company.Website[0].URL}
                          </a>
                        </p>
                      </IonItem>
                    ) : null}
                    {this.state.company.RecruitingURL?.length ? (
                      <IonItem>
                        <IonLabel
                          color="medium"
                          style={{ marginBottom: 0 }}
                          position="stacked"
                        >
                          Careers &amp; Opportunities
                        </IonLabel>
                        <p style={{ marginTop: 0, marginBottom: 10 }}>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.openUrl(this.state.company.RecruitingURL[0].URL);
                            }}
                          >
                            {this.state.company.RecruitingURL[0].URL}
                          </a>
                        </p>
                      </IonItem>
                    ) : null}
                    {this.generateAddressString(this.state.company) ? (
                      <IonItem>
                        <IonLabel
                          color="medium"
                          style={{ marginBottom: 0 }}
                          position="stacked"
                        >
                          Address
                        </IonLabel>
                        <p style={{ marginTop: 0, marginBottom: 10 }}>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.openUrl(
                                this.generateMapsURL(this.state.company)
                              );
                            }}
                          >
                            {this.generateAddressString(this.state.company)}
                          </a>
                        </p>
                      </IonItem>
                    ) : null}
                  </IonList>
                  {this.state.events && this.state.events.length ? (
                    <IonList>
                      <IonListHeader>
                        <IonLabel style={{ marginBottom: 10 }}>
                          Events We're Hosting
                        </IonLabel>
                      </IonListHeader>
                      {this.state.events
                        ? this.state.events.map((group: any, i: any) => {
                            console.log(group);

                            //console.log(date);
                            return (
                              <IonItemGroup key={i}>
                                <IonItemDivider color="light" sticky>
                                  <IonLabel>{group.Day}</IonLabel>
                                </IonItemDivider>
                                {group.Events.map((ev: any, j: any) => {
                                  //console.log(ev);

                                  const {
                                    id,
                                    Name,
                                    Start,
                                    End,
                                    Description,
                                    Location,
                                  } = ev;

                                  // Find Time Rang of Event
                                  var s = new Date(Start).toLocaleTimeString(
                                    [],
                                    {
                                      timeStyle: "short",
                                    }
                                  );
                                  var e = new Date(End).toLocaleTimeString([], {
                                    timeStyle: "short",
                                  });
                                  var TimeRangeReadable = s + "-" + e;

                                  // Find Duration of Event via Start time and End time.
                                  var st = new Date(Start).getTime();
                                  var en = new Date(End).getTime();
                                  var ms = en - st;
                                  var minutes = Math.floor(
                                    (ms / (1000 * 60)) % 60
                                  );
                                  var hours = Math.floor(
                                    (ms / (1000 * 60 * 60)) % 24
                                  );
                                  var Duration =
                                    hours == 0
                                      ? minutes + "min"
                                      : minutes != 0
                                      ? hours + "h " + minutes + "min"
                                      : hours + "h";

                                  return (
                                    <IonItem
                                      button
                                      onClick={() => {
                                        this.props.history.push(
                                          `/event/details/${ev.id}`
                                        );
                                      }}
                                      key={j}
                                    >
                                      <IonLabel>
                                        <h3
                                          style={{
                                            color: "var(--ion-color-secondary)",
                                          }}
                                        >
                                          {TimeRangeReadable}
                                        </h3>
                                        <h2
                                          style={{ fontWeight: "bold" }}
                                          className=""
                                        >
                                          {Name}
                                        </h2>
                                        <p
                                          style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {ev.Summary ? ev.Summary : ""}
                                        </p>
                                        <div style={{ marginTop: "0.5rem" }}>
                                          <IonRow className="ion-align-items-top">
                                            <IonCol sizeXs="auto">
                                              <IonBadge
                                                color="light"
                                                style={{
                                                  color:
                                                    "var(--ion-color-medium)",
                                                  textTransform: "lowercase",

                                                  height: "22px",
                                                  margin: "0px !important",
                                                }}
                                              >
                                                <IonIcon
                                                  style={{
                                                    position: "relative",
                                                    top: "1px",
                                                  }}
                                                  icon={time}
                                                ></IonIcon>{" "}
                                                {Duration}
                                              </IonBadge>
                                            </IonCol>
                                            {ev.Company || ev.Location ? (
                                              <IonCol sizeXs="auto">
                                                <IonBadge
                                                  color="light"
                                                  style={{
                                                    color:
                                                      "var(--ion-color-medium)",
                                                    maxWidth: "180px",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    height: "22px",
                                                    margin: "0px !important",
                                                  }}
                                                >
                                                  <IonIcon
                                                    style={{
                                                      position: "relative",
                                                      top: "1px",
                                                    }}
                                                    icon={location}
                                                  ></IonIcon>{" "}
                                                  {ev.Company
                                                    ? ev.Company.Name
                                                    : ev.Location}
                                                </IonBadge>
                                              </IonCol>
                                            ) : null}
                                          </IonRow>
                                        </div>
                                      </IonLabel>
                                    </IonItem>
                                  );
                                })}
                              </IonItemGroup>
                            );
                          })
                        : null}
                    </IonList>
                  ) : null}
                </div>
              </>
            ) : null}
          </IonContent>
        </IonPage>
      </>
    );
  }
}

export default withRouter(CompanyDetails);

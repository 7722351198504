import { Redirect, Route, useHistory, withRouter } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupConfig,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  albumsOutline,
  briefcaseOutline,
  calendar,
  calendarOutline,
  ellipse,
  mapOutline,
  personCircleOutline,
  square,
  triangle,
} from "ionicons/icons";
import Today from "./pages/Today";
import Agenda from "./pages/Agenda";
import Map from "./pages/Map";
import Account from "./pages/Account";
import Welcome from "./pages/Welcome";
import WelcomeEventCode from "./pages/WelcomeEventCode";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import WelcomeRegister from "./pages/WelcomeRegister";
import EventDetails from "./pages/EventDetails";
import Announcements from "./pages/Announcements";
import { Storage } from "@capacitor/storage";
import AnnouncementDetails from "./pages/AnnouncementDetails";
import UpcomingEvents from "./pages/UpcomingEvents";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import EventSponsors from "./pages/EventSponsors";
import CompanyDetails from "./pages/CompanyDetails";
import { useEffect, useState } from "react";
import SigninVerificationCode from "./pages/SigninVerificationCode";
import WelcomeSignin from "./pages/WelcomeSignin";
import { SplashScreen } from "@capacitor/splash-screen";
import EventPassportList from "./pages/EventPassportList";
import MapWaypointDetails from "./pages/MapWaypointDetails";

import { BranchDeepLinks, BranchInitEvent } from "capacitor-branch-deep-links";

async function userIsAuthenticated() {
  try {
    let event: any = await Storage.get({ key: "data" });

    console.log(event);

    if (event.value) {
      return true;
    }

    return false;
  } catch (e: any) {
    return false;
  }
}

async function loadTheme() {
  try {
    let event: any = await Storage.get({ key: "event_object" });

    if (event.value) {
      event = JSON.parse(event.value);

      if (event && event.ThemeColor) {
        let root = document.documentElement;

        root.style.setProperty("--ion-color-primary", event.ThemeColor);
      }
    }
  } catch (e: any) {
    console.error(e);
  }
}

if (Capacitor.isNativePlatform()) {
  try {
    Keyboard.setAccessoryBarVisible({ isVisible: true });
  } catch (e: any) {
    console.error(e);
  }
}

loadTheme();

userIsAuthenticated().then((authd) => {
  if (!authd && window.location.href.indexOf("welcome") <= 0) {
    window.location.href = "/welcome/code";
  }

  if (Capacitor.isNativePlatform()) {
    SplashScreen.hide();
  }
});

const App: React.FC = () => {
  let history = useHistory();

  if (Capacitor.isNativePlatform()) {
    BranchDeepLinks.addListener("init", (event: BranchInitEvent) => {
      // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
      // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic

      if (
        event.referringParams &&
        event.referringParams.feature &&
        event.referringParams.feature == "eventCode" &&
        event.referringParams.code
      ) {
        window.location.href =
          "/welcome/code?code=" + event.referringParams.code;
      }
    });

    BranchDeepLinks.addListener("initError", (error: any) => {
      console.error(error);
    });
  }

  const [animated, setAnimated] = useState(true);

  const handleTabClick: any = (href: string) => (ref: any) => {
    if (!ref) {
      return;
    }

    //console.log(ref);

    ref.handleIonTabButtonClick = () => {
      //console.log(history.length)

      setAnimated(false);

      history.replace(href);

      setTimeout(() => {
        setAnimated(true);
      }, 100);
    };
  };

  return (
    <IonApp>
      <IonReactRouter history={history}>
        <IonRouterOutlet>
          <Route exact path="/welcome" component={Welcome} />
          <Route exact path="/welcome/code" component={WelcomeEventCode} />
          <Route
            exact
            path="/welcome/signin/code"
            component={SigninVerificationCode}
          />
          <Route exact path="/welcome/signin" component={WelcomeSignin} />
          <Route exact path="/welcome/register" component={WelcomeRegister} />

          <Route
            path="/event"
            render={() => (
              <IonTabs>
                <IonRouterOutlet animated={animated}>
                  <Route path="/event/today" exact component={Today} />

                  <Route path="/event/agenda" exact component={Agenda} />

                  <Route
                    path="/event/details/:eventID"
                    component={EventDetails}
                  />
                  <Route
                    path="/event/announcements/:announcementID"
                    component={AnnouncementDetails}
                  />
                  <Route
                    path="/event/map/:waypointID"
                    component={MapWaypointDetails}
                  />
                  <Route
                    path="/event/companies/:companyID"
                    component={CompanyDetails}
                  />
                  <Route
                    path="/event/announcements"
                    exact
                    component={Announcements}
                  />
                  <Route
                    path="/event/sponsors"
                    exact
                    component={EventSponsors}
                  />

                  <Route path="/event/companies" exact component={Map} />

                  <Route path="/event/account" exact component={Account} />
                  <Route
                    path="/event/account/passport"
                    exact
                    component={EventPassportList}
                  />
                  <Route
                    path="/event/upcoming"
                    exact
                    component={UpcomingEvents}
                  />

                  <Route exact path="/event">
                    <Redirect to="/event/today" />
                  </Route>
                </IonRouterOutlet>
                <IonTabBar color="primary" slot="bottom">
                  <IonTabButton
                    tab="today"
                    href="/event/today"
                    ref={handleTabClick("/event/today")}
                  >
                    <IonIcon icon={albumsOutline} />
                    <IonLabel>Today</IonLabel>
                  </IonTabButton>
                  <IonTabButton
                    tab="agenda"
                    href="/event/agenda"
                    ref={handleTabClick("/event/agenda")}
                  >
                    <IonIcon icon={calendarOutline} />
                    <IonLabel>Schedule</IonLabel>
                  </IonTabButton>
                  <IonTabButton
                    href="/event/companies"
                    ref={handleTabClick("/event/companies")}
                    tab="companies"
                  >
                    <IonIcon icon={briefcaseOutline} />
                    <IonLabel>Companies</IonLabel>
                  </IonTabButton>
                  <IonTabButton
                    tab="account"
                    href="/event/account"
                    ref={handleTabClick("/event/account")}
                  >
                    <IonIcon icon={personCircleOutline} />
                    <IonLabel>Account</IonLabel>
                  </IonTabButton>
                </IonTabBar>
              </IonTabs>
            )}
          ></Route>
          <Route exact path="/">
            {userIsAuthenticated() ? (
              <Redirect to="/event/today" />
            ) : (
              <Redirect to="/welcome/code" />
            )}
          </Route>
          <Route exact path="/welcome">
            <Redirect to="/welcome/code" />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
      <IonReactRouter></IonReactRouter>
    </IonApp>
  );
};

export default withRouter(App);

import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonSlides,
  IonSlide,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
} from "@ionic/react";
import {
  PushNotificationSchema,
  PushNotifications,
  Token,
  ActionPerformed,
} from "@capacitor/push-notifications";
import { Toast } from "@capacitor/toast";
import { chevronForward, notifications } from "ionicons/icons";
import React from "react";
import { Capacitor } from "@capacitor/core";
import { Storage } from "@capacitor/storage";

class EnableNotificationsWidget extends React.Component {
  state = {
    reg: "",
    show: false,
  };

  isMobilePlatform() {
    return Capacitor.isNativePlatform();
  }

  async requestPermissions() {
    return new Promise((resolve) => {
      PushNotifications.requestPermissions().then((res) => {
        if (res.receive === "denied") {
          //this.showToast("Push Notification permission denied");
        } else {
          //this.showToast("Push Notification permission granted");
        }

        this.setState({
          show: false,
        });

        Storage.set({ key: "notifications_requested", value: "true" });

        resolve(res.receive !== "denied");
      });
    });
  }

  async componentDidMount() {
    if (this.isMobilePlatform()) {
      let value = await Storage.get({ key: "notifications_requested" });

      if (value && value.value && value.value == "true") {
        this.setState({
          show: false,
        });
      } else {
        this.setState({
          show: true,
        });
      }
    }
  }

  async register() {
    let result = await this.requestPermissions();

    if (!result) {
      Storage.set({ key: "notifications_requested", value: "true" });

      this.setState({
        show: false,
      });

      return;
    }

    // Register with Google to receive push via FCM
    let reg = PushNotifications.register();
    this.setState({ reg });
    PushNotifications.addListener("registration", (token: Token) => {
      Storage.set({ key: "notifications_requested", value: "true" });

      this.setState({
        show: false,
      });
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      alert("Error on registration: " + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        alert("Push received: " + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        alert("Push action performed: " + JSON.stringify(notification));
      }
    );
  }

  async showToast(msg: string) {
    await Toast.show({
      text: msg,
    });
  }

  render() {
    return (
      <>
        {this.isMobilePlatform() && this.state.show ? (
          !this.state.reg ? (
            <IonCard
              button={true}
              onClick={this.register.bind(this)}
              color="primary"
            >
              <IonCardContent>
                <IonGrid style={{ padding: 0 }}>
                  <IonRow className="ion-align-items-center">
                    <IonCol size="auto" style={{ paddingLeft: 0 }}>
                      <IonIcon
                        style={{
                          position: "relative",
                          top: "2px",
                          paddingRight: "0.5rem",
                        }}
                        icon={notifications}
                        size="small"
                      ></IonIcon>
                    </IonCol>
                    <IonCol>Enable notifications for event reminders</IonCol>
                    <IonCol style={{ paddingRight: 0 }} size="auto">
                      <IonIcon
                        style={{ position: "relative", top: "2px" }}
                        icon={chevronForward}
                        size="small"
                      ></IonIcon>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
          ) : (
            <IonCard color="primary">
              <IonCardContent>
                <IonGrid style={{ padding: 0 }}>
                  <IonRow className="ion-align-items-center">
                    <IonCol>notifications Enabled for event reminders</IonCol>
                    <IonCol style={{ paddingRight: 0 }} size="auto">
                      <IonIcon
                        style={{ position: "relative", top: "2px" }}
                        icon={chevronForward}
                        size="small"
                      ></IonIcon>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
          )
        ) : null}
      </>
    );
  }
}

export default EnableNotificationsWidget;

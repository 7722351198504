import http from "../services/http.common";

class ServicesAPI {
  getAll(ApiName: any) {
    return http.get(ApiName);
  }

  getById(ApiName: any, id: any) {
    return http.get(`${ApiName}/${id}`);
  }

  getFileURL(url = '', exp = 10000000) {
    return http.get(`/tenant/file/url?file=${url}&exp=${exp}`);
  }

  search(ApiName: any, data: any) {
    return http.put(`${ApiName}/search`, data);
  }

  getByBoj(ApiName: any, data: any) {
    return http.post(ApiName, data);
  }

  CreateUpdate(ApiName: any, data: any) {
    return http.post(ApiName, data);
  }

  update(ApiName: any, data: any) {
    return http.put(ApiName, data);
  }

  deleteByID(ApiName: any, id: any) {
    return http.delete(`${ApiName}/${id}`);
  }
}

export default new ServicesAPI();
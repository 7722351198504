import React from 'react';
import './ExploreContainer.css';


class QRCodeScanIcon extends React.Component<{height : string, fill : string}> {
    
  
    render() {
      return (
    <>
        <svg style={{height: this.props.height ? this.props.height : '20px'}} data-name="Layer 1" viewBox="0 0 100 125" x="0px" y="0px"><g><path fill={this.props.fill ? this.props.fill: '#FFFFFF'} d="M72,20H60v8H72V40h8V28A8,8,0,0,0,72,20Z"/><path fill={this.props.fill ? this.props.fill: '#FFFFFF'} d="M20,28V40h8V28H40V20H28A8,8,0,0,0,20,28Z"/><path fill={this.props.fill ? this.props.fill: '#FFFFFF'} d="M28,60H20V72a8,8,0,0,0,8,8H40V72H28Z"/><path fill={this.props.fill ? this.props.fill: '#FFFFFF'} d="M72,72H60v8H72a8,8,0,0,0,8-8V60H72Z"/><rect fill={this.props.fill ? this.props.fill: '#FFFFFF'} x="46" y="46" width="8" height="8"/><rect fill={this.props.fill ? this.props.fill: '#FFFFFF'} x="54" y="38" width="8" height="8"/><rect fill={this.props.fill ? this.props.fill: '#FFFFFF'} x="38" y="38" width="8" height="8"/><rect fill={this.props.fill ? this.props.fill: '#FFFFFF'} x="54" y="54" width="8" height="8"/><rect fill={this.props.fill ? this.props.fill: '#FFFFFF'} x="38" y="54" width="8" height="8"/></g></svg>
    </>
  );
  }
};

export default QRCodeScanIcon;

import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import CompanyListView from "../components/CompanyListView";
import CompanyMapView from "../components/CompanyMapView";
import ExploreContainer from "../components/ExploreContainer";

class Companies extends React.Component {
  state: any = {
    view: "list",
  };

  componentDidMount() {
    console.log(window.location.hash);

    if (window.location.hash) {
      let hash = window.location.hash;

      this.setState({
        view: hash.replace("#", ""),
      });
    }
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Companies</IonTitle>
          </IonToolbar>
          <IonToolbar>
            <IonSegment
              value={this.state.view}
              onIonChange={(e) => {
                this.setState({ view: e.detail.value });

                window.location.hash = "#" + e.detail.value;
              }}
            >
              <IonSegmentButton value="list">
                <IonLabel>List</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="map">
                <IonLabel>Map</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        </IonHeader>
        {this.state.view == "list" ? (
          <CompanyListView></CompanyListView>
        ) : (
          <CompanyMapView></CompanyMapView>
        )}
      </IonPage>
    );
  }
}

export default Companies;

import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonSlides,
  IonSlide,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
} from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import React from "react";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import httpService from "../services/http.service";
import { EventSponsors } from "../services/api.common";
import { Storage } from "@capacitor/storage";
import { withRouter } from "react-router";

class EventSponsorsWidget extends React.Component<any> {
  state: any = {
    images: [],
  };

  openSponsorSite(sponsor: any) {
    if (!sponsor) {
      return;
    }

    /*if (Capacitor.isNativePlatform()) {
      Browser.open({ url, presentationStyle: "popover" });

      return;
    }*/

    this.props.history.push("/event/companies/" + sponsor.id);
  }

  loadSponsors() {
    let query = {
      Fields: ["SponsorCompany", "id"],
      Filters: [
        {
          Field: "SponsorshipLevel",
          Method: "equals",
          Value: "TopTier",
        },
      ],
    };

    httpService.search(EventSponsors, { Query: query }).then(
      (data) => {
        console.log(data.data.data.data);

        if (
          data &&
          data.data &&
          data.data.data &&
          data.data.data.data &&
          data.data.data.data.length
        ) {
          try {
            let promises = [];
            let images: any = [];

            for (let i = 0; i < data.data.data.data.length; i++) {
              const company = data.data.data.data[i].SponsorCompany;

              if (!company || !company.LogoImage) {
                continue;
              }

              const prom = httpService.getFileURL(company.LogoImage).then(
                (data) => {
                  images.push({
                    image_url: data.data.data.url,
                    website_url: company.Website,
                    company,
                  });
                },
                (e) => {
                  console.error(e);
                }
              );

              promises.push(prom);
            }

            Promise.all(promises).then(() => {
              setTimeout(() => {
                this.setState({
                  images,
                });
              }, 0);

              Storage.set({
                key: "sponsor_images",
                value: JSON.stringify(images),
              });
            });
          } catch (e) {
            console.error(e);
          }
        }
      },
      (e) => {
        console.error(e);
      }
    );
  }

  async componentDidMount() {
    this.loadSponsors();

    let existing: any = await Storage.get({
      key: "sponsor_images",
    });

    if (existing.value) {
      try {
        let images = JSON.parse(existing.value);

        this.setState({ images });
      } catch (e) {
        console.error(e);
      }
    }
  }

  render() {
    return (
      <>
        {this.state.images && this.state.images.length ? (
          <>
            <div
              className="ion-padding ion-text-uppercase ion-color-medium"
              style={{ fontWeight: "bold" }}
            >
              <IonGrid style={{ padding: 0 }}>
                <IonRow>
                  <IonCol>Sponsored By</IonCol>
                  <IonCol
                    size="auto"
                    className="ion-text-right"
                    onClick={() => {
                      this.props.history.push("/event/sponsors");
                    }}
                  >
                    All{" "}
                    <IonIcon
                      icon={chevronForward}
                      style={{ position: "relative", top: "2px" }}
                    ></IonIcon>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <IonSlides
              style={{ width: "100%" }}
              pager={true}
              options={{ initialSide: 1, speed: 400 }}
            >
              {this.state.images
                ? this.state.images.map((im: any, i: number) => (
                    <React.Fragment>
                      <IonSlide style={{ paddingBottom: "1rem" }} key={i}>
                        <IonCard
                          button={true}
                          style={{ width: "100%", marginTop: 0 }}
                          onClick={() => this.openSponsorSite(im.company)}
                        >
                          <IonCardContent
                            className="ion-text-center ion-justify-content-center"
                            style={{ height: "200px", padding: "2.5rem" }}
                          >
                            <img
                              src={im.image_url}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                position: "relative",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            ></img>
                          </IonCardContent>
                        </IonCard>
                      </IonSlide>
                    </React.Fragment>
                  ))
                : null}
            </IonSlides>
          </>
        ) : null}
      </>
    );
  }
}

export default withRouter(EventSponsorsWidget);

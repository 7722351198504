import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import ExploreContainer from "../components/ExploreContainer";
import QRCodeScanIcon from "../components/QRCodeScanIcon";
import RightArrow from "../components/RightArrow";
import "./Account.css";
import { Capacitor } from "@capacitor/core";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { closeCircle } from "ionicons/icons";
import { withRouter } from "react-router";
import Http from "../services/http.service";
import { EventCodeSearch } from "../services/api.common";
import { Storage } from "@capacitor/storage";

class WelcomeEventCode extends React.Component<any> {
  state = {
    pageStyle: {},
    closeButtonStyle: {
      display: "none",
    },
    eventCode: "",
    submitting: false,
  };

  isMobilePlatform() {
    return Capacitor.isNativePlatform();
  }

  async loadTheme() {
    try {
      let event: any = await Storage.get({ key: "event_object" });

      if (event.value) {
        event = JSON.parse(event.value);

        if (event && event.ThemeColor) {
          let root = document.documentElement;

          root.style.setProperty("--ion-color-primary", event.ThemeColor);
        }
      }
    } catch (e: any) {
      console.error(e);
    }
  }

  // function for Show Alert
  alertErr() {
    const alert = document.createElement("ion-alert");
    alert.header = "Alert";
    //alert.subHeader = 'Subtitle';
    alert.message = "Please Check Your Event Code";
    alert.buttons = ["OK"];

    document.body.appendChild(alert);
    return alert.present();
  }

  // Check EventCode Valid Or Not.
  async handleSubmit() {
    const data = {
      Query: {
        Fields: ["*"],
        Filters: [
          {
            Group: false,
            Field: "Code",
            Method: "equals",
            Value: this.state.eventCode
              ? this.state.eventCode.toUpperCase()
              : "",
          },
        ],
      },
    };

    this.setState({
      submitting: true,
    });

    console.log("eventcode", this.state.eventCode);
    await Http.update(`${EventCodeSearch}`, data)
      .then((response: any) => {
        console.log(response.data.data.count);
        // if EventCode Valid then Proceed to register Page.
        if (response.data.data.count) {
          const Event_id = response.data.data.data[0].id;
          const eventName = response.data.data.data[0].Title;

          const data = {
            eventCode: Event_id,
            event: response.data.data.data[0],
          };
          Storage.set({
            key: "event",
            value: Event_id,
          });
          Storage.set({
            key: "event_object",
            value: JSON.stringify(response.data.data.data[0]),
          });

          console.log("success");
          this.props.history.push({
            pathname: "/welcome/register",
            state: data,
          });
          // If EventCode Not Valid Show Alert
        } else {
          console.log("Check Your Event Code");
          this.alertErr();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  async startQRScan() {
    try {
      this.setState({
        pageStyle: {
          display: "none",
        },
        closeButtonStyle: { position: "fixed", top: "50px", right: "20px" },
      });

      BarcodeScanner.hideBackground();

      const result: any = await BarcodeScanner.startScan(); // start scanning and wait for a result

      // if the result has content
      if (result.hasContent) {
        console.log(result.content); // log the raw scanned content

        if (
          result.content &&
          result.content.indexOf("https://growthevents.com") >= 0
        ) {
          this.setState({
            eventCode: result.content.replace("https://growthevents.com/", ""),
          });
        } else if (
          result.content &&
          result.content.indexOf("https://growthevent.app.link") >= 0
        ) {
          this.setState({
            eventCode: result.content.replace(
              "https://growthevent.app.link/",
              ""
            ),
          });
        } else {
          this.setState({
            eventCode: result.content,
          });
        }

        this.handleSubmit();

        this.setState({
          pageStyle: {},
          closeButtonStyle: { display: "none" },
        });
      }
    } catch (e: any) {
      console.error(e);
    }
  }

  async endQRScan(e: any) {
    if (e) {
      e.preventDefault();
    }

    try {
      this.setState({
        pageStyle: {},
        closeButtonStyle: { display: "none" },
      });

      const result: any = await BarcodeScanner.stopScan(); // stop scanner
    } catch (e: any) {
      console.error(e);
    }
  }

  componentDidMount() {
    if (this.isMobilePlatform()) {
      try {
        BarcodeScanner.prepare();
      } catch (e: any) {
        console.error(e);
      }
    }

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const code = urlParams.get("code");

    if (code) {
      this.setState(
        {
          eventCode: code,
        },
        () => {
          this.handleSubmit();
        }
      );
    }

    this.loadTheme();
  }

  render() {
    return (
      <IonPage>
        <IonContent style={this.state.pageStyle} scrollX={false} fullscreen>
          <div className="ion-padding" style={{ marginTop: "2rem" }}>
            <h1 style={{ fontWeight: 500, fontSize: "1.9rem" }}>
              Enter your event's code
            </h1>
            <p style={{ fontSize: "1.15rem" }}>
              Scan or enter your event's registration code below to continue.
            </p>
            <div style={{ marginTop: "6rem", paddingBottom: "5rem" }}>
              <IonInput
                style={{
                  border: "1px solid #EEE",
                  fontSize: "2.2rem",
                  textAlign: "center",
                  borderRadius: "10px",
                  textTransform: "uppercase",
                }}
                type="text"
                autocapitalize="characters"
                autocorrect="off"
                inputmode="text"
                pattern="text"
                name="eventCode"
                placeholder="ABC123"
                value={this.state.eventCode}
                onIonChange={(e) =>
                  this.setState({ eventCode: e.detail.value })
                }
              />
              {this.isMobilePlatform() ? (
                <div style={{ marginTop: "1.5rem", textAlign: "center" }}>
                  <IonButton onClick={this.startQRScan.bind(this)} color="dark">
                    <span
                      style={{
                        position: "relative",
                        left: "-10px",
                        top: "6px",
                      }}
                    >
                      <QRCodeScanIcon
                        height="45px"
                        fill="#FFFFFF"
                      ></QRCodeScanIcon>
                    </span>
                    Scan QR Code
                  </IonButton>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className="ion-padding"
            style={{
              zIndex: 100,
              background: "#FFFFFF",
              position: "fixed",
              bottom: 0,
              paddingBottom: "1.5rem",
              width: "100%",
            }}
          >
            <IonButton
              onClick={this.handleSubmit.bind(this)}
              expand="block"
              color="dark"
              size="large"
              disabled={this.state.submitting || !this.state.eventCode}
            >
              {this.state.submitting ? (
                <>
                  <IonSpinner></IonSpinner>
                </>
              ) : (
                <>
                  Continue
                  <span style={{ paddingLeft: "0.4rem" }}>
                    <RightArrow></RightArrow>
                  </span>
                </>
              )}
            </IonButton>
          </div>
        </IonContent>
        <div style={this.state.closeButtonStyle}>
          <a
            href="#"
            onClick={this.endQRScan.bind(this)}
            style={{ color: "#FFFFFF", fontSize: "2.2rem" }}
          >
            <IonIcon icon={closeCircle} color="white"></IonIcon>
          </a>
        </div>
      </IonPage>
    );
  }
}

export default withRouter(WelcomeEventCode);

import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { withRouter } from "react-router";
import { Storage } from "@capacitor/storage";
import _ from "underscore";
import httpService from "../services/http.service";
import { EventAnnouncements, EventSchedule } from "../services/api.common";
import Showdown from "showdown";
import {
  briefcase,
  business,
  calendar,
  calendarClear,
  location,
  star,
  time,
} from "ionicons/icons";
import moment from "moment";

class AnnouncementDetails extends React.Component<any> {
  state: any = {
    announcement: null,
    coverImageURL: "",
  };

  fetchAnnouncementByID(id: any) {
    httpService.getById(EventAnnouncements, id).then(
      (data) => {
        let announcement = data.data.data.data;

        const { Created } = announcement;

        var start = moment(Created);

        let dateString = start.format("dddd, MMMM Do");

        // Find Time Rang of Event
        var s = new Date(Created).toLocaleTimeString([], {
          timeStyle: "short",
        });

        dateString = dateString + " at " + s;

        this.setState({
          announcement,
          dateString,
        });
      },
      (e) => {
        console.error(e);

        if (e && e.response && e.response.status == 404) {
          this.props.history.replace("/event/today");

          return;
        }
      }
    );
  }

  renderHTML(event: any) {
    if (!event) {
      return "";
    }

    if (event.Description?.RichText) {
      let html = event.Description?.RichText

      return html;
    }

    return event.Summary ? event.Summary : "";
  }

  async componentDidMount() {
    if (
      !this.props.match ||
      !this.props.match.params ||
      !this.props.match.params.announcementID
    ) {
      this.props.history.replace("/event/today");

      return;
    }

    let eventID = this.props.match.params.announcementID;

    this.fetchAnnouncementByID(eventID);

    let existing = await Storage.get({ key: "announcements" });

    if (existing.value) {
      try {
        let schedule = JSON.parse(existing.value);

        if (schedule) {
          for (let i = 0; i < schedule.length; i++) {
            const day = schedule[i];

            let event = _.findWhere(day.Events, { id: eventID });

            if (event) {
              const { id, Name, Created } = event;

              var start = moment(Created);

              let dateString = start.format("dddd, MMMM Do");

              // Find Time Rang of Event
              var s = new Date(Created).toLocaleTimeString([], {
                timeStyle: "short",
              });

              dateString = dateString + " at " + s;

              this.setState({
                announcement: event,
                dateString,
              });

              break;
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  render() {
    return (
      <>
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Announcement</IonTitle>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/event/today" />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {this.state.announcement ? (
              <>
                <div
                  className="ion-padding"
                  style={{
                    borderTopLeftRadius: "",
                    borderTopRightRadius: "",
                    marginTop: "",
                    background: "#FFF",
                    width: "100%",
                    boxShadow: "",
                    minHeight: "calc(100vh - 300px - 85px)",
                  }}
                >
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      color: "var(--ion-color-medium)",
                    }}
                  >
                    {this.state.dateString}
                  </p>
                  <h2 style={{ fontWeight: "bold", marginTop: "5px" }}>
                    {this.state.announcement.Title}
                  </h2>
                  <div
                    style={{
                      color: "var(--ion-color-medium)",
                      marginBottom: "30px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: this.renderHTML(this.state.announcement),
                    }}
                  ></div>
                </div>
              </>
            ) : null}
          </IonContent>
        </IonPage>
      </>
    );
  }
}

export default withRouter(AnnouncementDetails);

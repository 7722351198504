import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { withRouter } from "react-router";
import { Storage } from "@capacitor/storage";
import httpService from "../services/http.service";
import _ from "underscore";
import { EventAnnouncements } from "../services/api.common";

class Announcements extends React.Component<any> {
  state: any = {
    announcements: [],
    loading: true,
  };

  async fetchAnnouncements(showLoading: boolean = false) {
    let data: any = await Storage.get({ key: "event" });

    let query = {
      Fields: ["*"],
      Filters: [
        {
          Field: "Event",
          Method: "equals",
          Value: data.value,
        },
      ],
      OrderBy: {
        Field: "Created",
        Direction: "DESC",
      },
    };

    if (showLoading) {
      this.setState({
        loading: true,
      });
    }

    await httpService
      .search(`${EventAnnouncements}`, { Query: query })
      .then((response: any) => {
        let events = [];

        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.data &&
          response.data.data.data.length
        ) {
          events = response.data.data.data;
        }

        let eventState: any = [];

        for (let i = 0; i < events.length; i++) {
          const evt: any = events[i];

          let date = new Date(evt.Created).toDateString().substring(0, 15);

          let existingIDX = _.findIndex(eventState, { Day: date });

          if (existingIDX >= 0) {
            eventState[existingIDX].Events.push(evt);

            const sorted = eventState[existingIDX].Events.sort(
              (a: any, b: any) => {
                const bDate: any = new Date(b.Created);
                const aDate: any = new Date(a.Created);

                return aDate - bDate;
              }
            );

            eventState[existingIDX].Events = sorted;
          } else {
            let payload: any = {
              Day: date,
              Events: [],
            };

            payload.Events.push(evt);

            eventState.push(payload);
          }
        }

        Storage.set({
          key: "announcements",
          value: JSON.stringify(eventState),
        });

        // console.log(events);
        this.setState({ announcements: eventState });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  async componentDidMount() {
    this.fetchAnnouncements();

    let existing: any = await Storage.get({
      key: "announcements",
    });

    if (existing.value) {
      try {
        let events = JSON.parse(existing.value);

        this.setState({
          loading: false,
        });

        this.setState({ announcements: events });
      } catch (e) {
        console.error(e);
      }
    }
  }

  refresh(event: CustomEvent<any>) {
    this.fetchAnnouncements(true).then(() => {
      console.log("refresh complete");
      event.detail.complete();
    });
  }

  render() {
    return (
      <>
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Announcements</IonTitle>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/event/today" />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonRefresher slot="fixed" onIonRefresh={this.refresh.bind(this)}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            {this.state.loading ? (
              <IonList>
                <IonItemGroup>
                  <IonItemDivider color="light" sticky>
                    <IonLabel>
                      <IonSkeletonText
                        animated
                        style={{ height: "17px", width: "200px" }}
                      ></IonSkeletonText>
                    </IonLabel>
                  </IonItemDivider>
                  <IonItem button>
                    <IonLabel>
                      <h3
                        style={{
                          color: "var(--ion-color-secondary)",
                        }}
                      >
                        <IonSkeletonText
                          animated
                          style={{ height: "12px", width: "140px" }}
                        ></IonSkeletonText>
                      </h3>
                      <h2 style={{ fontWeight: "bold" }} className="">
                        <IonSkeletonText
                          animated
                          style={{ height: "18px", width: "240px" }}
                        ></IonSkeletonText>
                      </h2>
                      <p
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <IonSkeletonText animated></IonSkeletonText>
                      </p>
                    </IonLabel>
                  </IonItem>
                  <IonItem button>
                    <IonLabel>
                      <h3
                        style={{
                          color: "var(--ion-color-secondary)",
                        }}
                      >
                        <IonSkeletonText
                          animated
                          style={{ height: "12px", width: "140px" }}
                        ></IonSkeletonText>
                      </h3>
                      <h2 style={{ fontWeight: "bold" }} className="">
                        <IonSkeletonText
                          animated
                          style={{ height: "18px", width: "240px" }}
                        ></IonSkeletonText>
                      </h2>
                      <p
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <IonSkeletonText animated></IonSkeletonText>
                      </p>
                    </IonLabel>
                  </IonItem>
                  <IonItem button>
                    <IonLabel>
                      <h3
                        style={{
                          color: "var(--ion-color-secondary)",
                        }}
                      >
                        <IonSkeletonText
                          animated
                          style={{ height: "12px", width: "140px" }}
                        ></IonSkeletonText>
                      </h3>
                      <h2 style={{ fontWeight: "bold" }} className="">
                        <IonSkeletonText
                          animated
                          style={{ height: "18px", width: "240px" }}
                        ></IonSkeletonText>
                      </h2>
                      <p
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <IonSkeletonText animated></IonSkeletonText>
                      </p>
                    </IonLabel>
                  </IonItem>
                  <IonItem button>
                    <IonLabel>
                      <h3
                        style={{
                          color: "var(--ion-color-secondary)",
                        }}
                      >
                        <IonSkeletonText
                          animated
                          style={{ height: "12px", width: "140px" }}
                        ></IonSkeletonText>
                      </h3>
                      <h2 style={{ fontWeight: "bold" }} className="">
                        <IonSkeletonText
                          animated
                          style={{ height: "18px", width: "240px" }}
                        ></IonSkeletonText>
                      </h2>
                      <p
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <IonSkeletonText animated></IonSkeletonText>
                      </p>
                    </IonLabel>
                  </IonItem>
                </IonItemGroup>
              </IonList>
            ) : (
              <IonList>
                {this.state.announcements
                  ? this.state.announcements.map((group: any, i: any) => {
                      console.log(group);

                      //console.log(date);
                      return (
                        <IonItemGroup key={i}>
                          <IonItemDivider color="light" sticky>
                            <IonLabel>{group.Day}</IonLabel>
                          </IonItemDivider>
                          {group.Events.map((ev: any, j: any) => {
                            //console.log(ev);

                            const {
                              id,
                              Title,
                              Start,
                              End,
                              Description,
                              Location,
                            } = ev;

                            // Find Time Rang of Event
                            var s = new Date(ev.Created).toDateString();

                            return (
                              <IonItem
                                button
                                onClick={() => {
                                  this.props.history.push(
                                    `/event/announcements/${ev.id}`
                                  );
                                }}
                                key={j}
                              >
                                <IonLabel>
                                  <h3
                                    style={{
                                      color: "var(--ion-color-secondary)",
                                    }}
                                  >
                                    {s}
                                  </h3>
                                  <h2
                                    style={{ fontWeight: "bold" }}
                                    className=""
                                  >
                                    {Title}
                                  </h2>
                                  <p
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {ev.Summary ? ev.Summary : ""}
                                  </p>
                                </IonLabel>
                              </IonItem>
                            );
                          })}
                        </IonItemGroup>
                      );
                    })
                  : null}
              </IonList>
            )}
          </IonContent>
        </IonPage>
      </>
    );
  }
}

export default withRouter(Announcements);

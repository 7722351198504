import {
  IonAlert,
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from "@ionic/react";
import React from "react";
import ExploreContainer from "../components/ExploreContainer";
import QRCodeScanIcon from "../components/QRCodeScanIcon";
import RightArrow from "../components/RightArrow";
import "./Account.css";
import Http from "../services/http.service";
import { CreateContact } from "../services/api.common";
import { withRouter } from "react-router";
import { Storage } from "@capacitor/storage";
import { isValidPhoneNumber } from "react-phone-number-input";

class WelcomeRegister extends React.Component<any> {
  state: any = {
    firstName: "",
    lastName: "",
    phone: {},
    email: "",
    school: "",
    eventCode: "",
    event: null,
    submitting: false,
  };

  isMobilePlatform() {
    return true;
  }

  // function for Show Alert
  alertErr(msg: any) {
    const alert = document.createElement("ion-alert");
    alert.header = "Alert";
    //alert.subHeader = 'Subtitle';
    alert.message = msg;
    alert.buttons = ["OK"];

    document.body.appendChild(alert);
    return alert.present();
  }

  makeid(length = 8) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async handleSubmit() {
    // console.log(this.state.firstName);
    // console.log(this.state.lastName);
    // console.log(this.state.email);
    // console.log(this.state.phone);
    // console.log(this.state.school);
    const createUser = {
      FirstName: this.state.firstName,
      LastName: this.state.lastName,
      Email: this.state.email,
      Mobile: this.state.phone.value,
      School: this.state.school,
      EventPassportCode: this.makeid(10),
    };

    console.log("submitting");

    this.setState({
      submitting: true,
    });

    await Http.CreateUpdate(`${CreateContact}`, createUser)
      .then((response: any) => {
        console.log(response);
        if (response.status === 200) {
          const userId = response.data.data.data.id;
          const data = { userId: userId, eventCode: this.state.eventCode };
          Storage.set({
            key: "data",
            value: userId,
          });
          Storage.set({
            key: "user",
            value: JSON.stringify(response.data.data.data),
          });
          this.props.history.push({
            pathname: "/event/today",
            state: data,
          });
        } else if (response.status === 409) {
          let msg =
            "you're already registered for this event. Please sign in to continue";
          this.alertErr(msg);
        } else {
          let msg = "something went wrong";
          this.alertErr(msg);
        }
      })
      .catch((err) => {
        console.log(err.Status);
      })
      .finally(() => {
        console.log("done submitting");

        this.setState({
          submitting: false,
        });
      });
  }

  async componentDidMount() {
    if (!this.props.location) {
      console.log("cant find location from props");
      return this.props.history.push("/welcome/code");
    }
    console.log(this.props.location);

    let event: any = await Storage.get({ key: "event_object" });

    if (!event.value) {
      console.log("cant find location from props");
      return this.props.history.push("/welcome/code");
    }

    event = JSON.parse(event.value);

    console.log(event);

    //console.log(this.props.location.state);
    this.setState({
      eventCode: event.EventCode,
      event: event,
    });
  }

  render() {
    return (
      <IonPage>
        <IonContent scrollX={false} fullscreen>
          <div className="ion-padding" style={{ marginTop: "2rem" }}>
            <h1 style={{ fontWeight: 500, fontSize: "1.9rem" }}>
              Welcome to{" "}
              {this.state.event && this.state.event.Name
                ? this.state.event.Name
                : "the event"}
              !
            </h1>
            <p style={{ fontSize: "1.15rem" }}>
              Enter your information below to get registered.
            </p>
            <p style={{ fontSize: "1.15rem" }}>
              <a href="/welcome/signin" style={{ textDecoration: "none" }}>
                Already registered? Sign In <RightArrow></RightArrow>
              </a>
            </p>
          </div>
          <div style={{ marginTop: "0rem", paddingBottom: "5rem" }}>
            <IonList>
              <IonItem>
                <IonLabel position="stacked" color="dark">
                  First Name
                </IonLabel>
                <IonInput
                  type="text"
                  inputmode="text"
                  pattern="text"
                  name="firstname"
                  placeholder="Your First Name"
                  value={this.state.firstName}
                  onIonChange={(e) => {
                    this.setState({ firstName: e.detail.value });
                  }}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="dark">
                  Last Name
                </IonLabel>
                <IonInput
                  type="text"
                  inputmode="text"
                  pattern="text"
                  name="lastname"
                  placeholder="Your Last Name"
                  value={this.state.lastName}
                  onIonChange={(e) => {
                    this.setState({ lastName: e.detail.value });
                  }}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="dark">
                  Phone
                </IonLabel>
                <IonInput
                  inputmode="tel"
                  pattern="tel"
                  type="tel"
                  name="phone"
                  placeholder="(585) 480-2021"
                  value={this.state.phone.formatted}
                  onIonChange={(e) => {
                    let value: any = e.detail.value;

                    const formatToPhone = (event: any) => {
                      let val = event.detail.value ? event.detail.value : "";

                      val = val.replace(/[^\d]/g, "");

                      let out = val;
                      let outRaw = "";

                      const input = val.replace(/\D/g, "").substring(0, 10); // First ten digits of input only

                      out = input;
                      outRaw = input;

                      const areaCode = input.substring(0, 3);
                      const middle = input.substring(3, 6);
                      const last = input.substring(6, 10);

                      if (input.length > 6) {
                        out = `(${areaCode}) ${middle}-${last}`;
                        outRaw = `+1${areaCode}${middle}${last}`;
                      } else if (input.length > 3) {
                        out = `(${areaCode}) ${middle}`;
                        outRaw = `+1${areaCode}${middle}`;
                      } else if (input.length > 0) {
                        out = `(${areaCode}`;
                        outRaw = `+1${areaCode}`;
                      }

                      return { formatted: out, value: outRaw };
                    };

                    let formatted = formatToPhone(e);

                    console.log(formatted);

                    this.setState({
                      phone: formatted,
                    });
                  }}
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="dark">
                  Email
                </IonLabel>
                <IonInput
                  type="email"
                  inputmode="email"
                  pattern="email"
                  name="email"
                  placeholder="you@growthevents.com"
                  value={this.state.email}
                  onIonChange={(e) => {
                    this.setState({ email: e.detail.value });
                  }}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="dark">
                  School
                </IonLabel>
                <IonSelect
                  placeholder="Select Your School"
                  okText="Okay"
                  cancelText="Dismiss"
                  value={this.state.school}
                  onIonChange={(e) => {
                    this.setState({ school: e.detail.value });
                  }}
                >
                  <IonSelectOption value="RIT">RIT</IonSelectOption>
                  <IonSelectOption value="UR">
                    University of Rochester
                  </IonSelectOption>
                  <IonSelectOption value="St. John Fisher College">
                    St. John Fisher College
                  </IonSelectOption>
                  <IonSelectOption value="Nazareth">
                    Nazareth College
                  </IonSelectOption>
                  <IonSelectOption value="MCC">
                    Monroe Community College
                  </IonSelectOption>
                  <IonSelectOption value="Brockport">
                    SUNY Brockport
                  </IonSelectOption>
                  <IonSelectOption value="Other">Other</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonList>
          </div>

          <div
            className="ion-padding"
            style={{
              zIndex: 100,
              background: "#FFFFFF",
              position: "fixed",
              bottom: 0,
              paddingBottom: "1.5rem",
              width: "100%",
            }}
          >
            <IonButton
              onClick={this.handleSubmit.bind(this)}
              expand="block"
              color="primary"
              size="large"
              disabled={
                this.state.submitting ||
                !this.state.phone ||
                !this.state.phone.value ||
                !isValidPhoneNumber(this.state.phone.value) ||
                !this.state.firstName ||
                !this.state.lastName
              }
            >
              {this.state.submitting ? (
                <>
                  <IonSpinner></IonSpinner>
                </>
              ) : (
                <>
                  Register
                  <span style={{ paddingLeft: "0.4rem" }}>
                    <RightArrow></RightArrow>
                  </span>
                </>
              )}
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(withRouter(WelcomeRegister));

import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonSlides,
  IonSlide,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  setupConfig,
} from "@ionic/react";
import { chevronForward, compassOutline } from "ionicons/icons";
import React from "react";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import httpService from "../services/http.service";
import { EventMapSearch, EventSponsors } from "../services/api.common";
import { Storage } from "@capacitor/storage";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Geolocation } from "@capacitor/geolocation";
import { withRouter } from "react-router";

import markerIcon from "leaflet/dist/images/marker-icon-2x.png";
import "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/images/layers.png";
import "leaflet/dist/images/layers-2x.png";

class CompanyMapView extends React.Component<any> {
  state: any = {
    images: [],
    markers: [],
  };

  async initMap() {
    return new Promise((resolve) => {
      setTimeout(() => {
        let map = L.map("googleMap").setView([43.15453, -77.60471], 15);

        // add the OpenStreetMap tiles
        L.tileLayer(
          "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
          {
            attribution:
              '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: "abcd",
            maxZoom: 19,
          }
        ).addTo(map);

        // show the scale bar on the lower left corner
        L.control.scale().addTo(map);

        this.setState(
          {
            map,
          },
          () => {
            resolve(true);
          }
        );
      }, 50);
    });
  }

  renderPopup(waypoint: any) {
    if (!waypoint) {
      return `<></>`;
    }

    console.log(waypoint);

    return `<div>
        <h5 style="margin-bottom: 0px;">${waypoint.LocationName}</h5>
        <p style="margin-top: 10px; font-size: 14px">${waypoint.LocationDescription}</p>
        <ion-button href="/event/map/${waypoint.id}" routerDirection="forward" size="small" color="light" class="ion-color ion-color-light button button-small button-solid ion-activatable ion-focusable hydrated">More Information&nbsp;&nbsp;→</ion-button>
        
    </div>`;
  }

  renderWaypointsOnMap(waypoints: any) {
    if (!waypoints || !waypoints.length) {
      return;
    }

    var icon = L.Icon.extend({
      options: {
        iconUrl:
          require("../../node_modules/leaflet/dist/images/marker-icon.png")
            .default,
        shadowUrl:
          require("../../node_modules/leaflet/dist/images/marker-shadow.png")
            .default,
        iconSize: [25, 41],
        shadowSize: [41, 41],
        iconAnchor: [12, 41],

        popupAnchor: [1, -25],
      },
    });

    for (let i = 0; i < this.state.markers.length; i++) {
      this.state.map.removeLayer(this.state.markers[i]);
    }

    let markers = [];

    for (let i = 0; i < waypoints.length; i++) {
      let popup: any = this.renderPopup(waypoints[i]);

      let marker = L.marker(
        [parseFloat(waypoints[i].Latitude), parseFloat(waypoints[i].Longitude)],
        { icon: new icon() }
      )
        .addTo(this.state.map)
        .bindPopup(popup);

      markers.push(marker);
    }

    this.setState({
      markers,
    });
  }

  async fetchMapWaypoints(showLoading = false) {
    return new Promise(async (resolve) => {
      let event = await Storage.get({ key: "event" });

      let query = {
        Query: {
          Fields: ["*"],
          Filters: [
            {
              Field: "Event",
              Method: "equals",
              Value: event.value,
            },
          ],
        },
      };

      if (showLoading) {
        this.setState({
          loading: true,
        });
      }

      httpService
        .update(EventMapSearch, query)
        .then(
          (data) => {
            let records = data.data.data.data;

            let waypoints: any = [];

            console.log(records);

            for (let i = 0; i < records.length; i++) {
              waypoints.push(records[i]);
            }

            this.setState({ waypoints });

            Storage.set({
              key: "event_waypoints",
              value: JSON.stringify(waypoints),
            });

            this.renderWaypointsOnMap(waypoints);
          },
          (e: any) => {}
        )
        .finally(() => {
          resolve(true);

          this.setState({
            loading: false,
          });
        });
    });
  }

  async updateUserLocation() {
    if (Capacitor.isNativePlatform()) {
      let permissions = await Geolocation.checkPermissions();

      console.log("LOCATION PERMISSIONS");
      console.log(permissions);
    }
  }

  async componentDidMount() {
    let map = await this.initMap();

    let waypoints: any = await Storage.get({
      key: "event_waypoints",
    });

    if (waypoints.value) {
      try {
        this.renderWaypointsOnMap(JSON.parse(waypoints.value));
      } catch (e) {
        console.error(e);
      }
    }

    this.fetchMapWaypoints();

    this.updateUserLocation();
  }

  render() {
    return (
      <>
        <IonContent scrollY={false} forceOverscroll={false}>
          <div style={{ height: "calc(100vh - 120px)" }} id="googleMap"></div>
        </IonContent>
      </>
    );
  }
}

export default withRouter(CompanyMapView);

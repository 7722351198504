import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useHistory, withRouter } from "react-router";
import ExploreContainer from "../components/ExploreContainer";
import "./Account.css";
import { Storage } from "@capacitor/storage";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { App } from "@capacitor/app";
import { useState } from "react";

const Tab3: React.FC = () => {
  let history: any = useHistory();

  let [version, setVersion] = useState("");
  let [covidInfo, setCovidInfo] = useState("");
  let [eventWebsite, setEventWebsite] = useState("");

  let signout = () => {
    Storage.clear();

    history.push(`/welcome`);
  };

  if (Capacitor.isNativePlatform()) {
    App.getInfo().then((info) => {
      setVersion(info.version);
    });
  }

  let openUrl = (url: string = "") => {
    if (!url) {
      return;
    }

    if (Capacitor.isNativePlatform()) {
      Browser.open({ url, presentationStyle: "popover" });

      return;
    }

    window.open(url, "_blank")?.focus();
  };

  Storage.get({ key: "event_object" }).then((value: any) => {
    if (!value) {
      return;
    }

    try {
      let event = JSON.parse(value.value);

      setEventWebsite(event.Website && event.Website.length > 0 ? event.Website[0].URL : "");
      setCovidInfo(event.CovidInfoUrl && event.CovidInfoUrl.length > 0 ? event.CovidInfoUrl[0].URL : "");
    } catch (e: any) {
      console.error(e);
    }
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Account</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonListHeader>
              <IonLabel>General</IonLabel>
            </IonListHeader>
            <IonItem
              button
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push("/event/account/passport");
              }}
            >
              <IonLabel>My Event Passport</IonLabel>
            </IonItem>
            {eventWebsite ? (
              <IonItem
                button
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  openUrl(eventWebsite);
                }}
              >
                <IonLabel>Event Website</IonLabel>
              </IonItem>
            ) : null}
            {covidInfo ? (
              <IonItem
                button
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  openUrl(covidInfo);
                }}
              >
                <IonLabel>COVID-19 Information</IonLabel>
              </IonItem>
            ) : null}
            <IonItem button onClick={() => signout()}>
              <IonLabel>Sign Out</IonLabel>
            </IonItem>
          </IonList>
          <IonList>
            <IonListHeader>
              <IonLabel>App</IonLabel>
            </IonListHeader>
            <IonItem
              button
              onClick={() =>
                openUrl("https://www.iubenda.com/terms-and-conditions/10848945")
              }
            >
              <IonLabel>Terms &amp; Conditions</IonLabel>
            </IonItem>
            <IonItem
              button
              onClick={() =>
                openUrl("https://www.iubenda.com/privacy-policy/10848945")
              }
            >
              <IonLabel>Privacy Policy</IonLabel>
            </IonItem>
            <IonItem button href="mailto:support@growthcloud.com">
              <IonLabel>Give Feedback</IonLabel>
            </IonItem>
            {Capacitor.isNativePlatform() ? (
              <IonItem>
                <IonLabel>v{version}</IonLabel>
              </IonItem>
            ) : null}
          </IonList>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Tab3);

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRadio,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  chevronForward,
  closeCircle,
  notifications,
  notificationsCircle,
  qrCode,
  qrCodeOutline,
} from "ionicons/icons";
import React from "react";
import AnnouncementsWidget from "../components/AnnouncementsWidget";
import EnableNotificationsWidget from "../components/EnableNotificationsWidget";
import EventSponsorsWidget from "../components/EventSponsorsWidget";
import ExploreContainer from "../components/ExploreContainer";
import SocialMediaWidget from "../components/SocialMediaWidget";
import UpcomingEventsWidget from "../components/UpcomingEventsWidget";
import {
  Contacts,
  ContactSearch,
  EventCode,
  EventCodeSearch,
} from "../services/api.common";
import httpService from "../services/http.service";
import "./Today.css";
import { Storage } from "@capacitor/storage";
import axios from "axios";

let QRCode: any = require("qrcode");

class Today extends React.Component<any> {
  state: any = {
    modalOpen: false,
    eventPassportCode: "",
    eventPassportQRCode: "",
    event: null,
    coverImageURL: "",
  };

  todayRef: any;

  constructor(props: any) {
    super(props);

    this.todayRef = React.createRef();
  }

  setQRCode(code: string = "") {
    let self: any = this;

    QRCode.toDataURL(code, (error: any, url: string) => {
      if (error) console.error(error);

      self.setState({
        eventPassportQRCode: url,
      });
    });
  }

  openEventPassportModal() {
    this.setState({
      modalOpen: true,
    });
  }

  async loadCoverImage(imageURL: any) {
    if (!imageURL) {
      return;
    }

    httpService.getFileURL(imageURL).then((data) => {
      if (data && data.data && data.data.data && data.data.data.url) {
        this.setState({
          coverImageURL: data.data.data.url,
        });

        Storage.set({ key: "cover_image_url", value: data.data.data.url });
      }
    });
  }

  async loadEvent() {
    let eventID = await Storage.get({ key: "event" });

    let coverImageURL: any = Storage.get({ key: "cover_image_url" });

    if (coverImageURL.value) {
      try {
        let canLoad = await axios.get(coverImageURL.value);

        if (canLoad) {
          this.setState({ coverImageURL });
        }
      } catch (e) {}
    }

    if (eventID.value) {
      await httpService
        .getById(`${EventCode}`, eventID.value)
        .then((response: any) => {
          try {
            let event: any = response.data.data.data;

            this.setState({
              event,
            });

            this.loadCoverImage(event.CoverImage);

            Storage.set({ key: "event_object", value: JSON.stringify(event) });
          } catch (e: any) {
            console.error(e);
          }
        });
    }
  }

  async loadUser() {
    let eventID = await Storage.get({ key: "data" });

    if (eventID.value) {
      await httpService
        .getById(`${Contacts}`, eventID.value)
        .then((response: any) => {
          try {
            let user: any = response.data.data.data;

            console.log(user);

            this.setState({
              user,
            });

            this.setState({ eventPassportCode: user.EventPassportCode });
            this.setQRCode(user.EventPassportCode);

            Storage.set({ key: "user", value: JSON.stringify(user) });
          } catch (e: any) {
            console.error(e);
          }
        });
    }
  }

  async componentDidMount() {
    let code = "A1B2C3";

    let user: any = await Storage.get({ key: "user" });

    if (user.value) {
      try {
        user = JSON.parse(user.value);

        this.setState({ eventPassportCode: user.EventPassportCode });

        this.setState({ user });

        this.setQRCode(user.EventPassportCode);
      } catch (e: any) {
        console.error(e);
      }
    }

    this.loadUser();
    this.loadEvent();
  }

  refresh(event: CustomEvent<any>) {
    this.loadEvent();
    this.loadUser();

    setTimeout(() => {
      console.log("Async operation has ended");
      event.detail.complete();
    }, 2000);
  }

  render() {
    return (
      <IonPage ref={this.todayRef}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Today</IonTitle>
            <IonButtons slot="primary">
              <IonButton
                color="secondary"
                onClick={() => this.openEventPassportModal()}
              >
                <IonIcon color="dark" slot="icon-only" icon={qrCodeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonRefresher slot="fixed" onIonRefresh={this.refresh.bind(this)}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Today</IonTitle>
            </IonToolbar>
          </IonHeader>
          <div id="container">
            {this.state.coverImageURL ? (
              <IonCard>
                <IonCardContent style={{ padding: 0, textAlign: "center" }}>
                  <img
                    src={this.state.coverImageURL}
                    style={{
                      marginBottom: "-5px",
                      width: "100%",
                      maxWidth: "400px",
                    }}
                  ></img>
                </IonCardContent>
              </IonCard>
            ) : null}
            <EnableNotificationsWidget></EnableNotificationsWidget>
            <UpcomingEventsWidget></UpcomingEventsWidget>
            <AnnouncementsWidget></AnnouncementsWidget>
            <SocialMediaWidget></SocialMediaWidget>
            <EventSponsorsWidget></EventSponsorsWidget>
          </div>
          <IonModal
            isOpen={this.state.modalOpen}
            swipeToClose={true}
            presentingElement={this.todayRef.current}
            onDidDismiss={() => this.setState({ modalOpen: false })}
          >
            <IonHeader>
              <IonToolbar>
                <IonTitle>Event Passport</IonTitle>
                <IonButtons slot="primary">
                  <IonButton
                    color="secondary"
                    onClick={() => this.setState({ modalOpen: false })}
                  >
                    <IonIcon color="dark" slot="icon-only" icon={closeCircle} />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
              <div className="ion-padding">
                <div className="ion-text-center">
                  <h2>Scan Below To Check In</h2>
                  <p className="ion-color-medium">
                    Each time you attend an event, have your Event Passport
                    scanned to get attendance and save them for later.
                  </p>
                </div>
                <div
                  className="ion-text-center"
                  style={{ marginBottom: "2.5rem", marginTop: "2rem" }}
                >
                  <img
                    alt="Event Passport QR COde"
                    id="eventPassportQRCode"
                    src={this.state.eventPassportQRCode}
                    style={{ width: "220px", height: "220px" }}
                  />
                </div>
                <div
                  className="ion-text-center"
                  style={{ paddingBottom: "2rem" }}
                >
                  <p>or manually enter the code:</p>
                  <h2>{this.state.eventPassportCode}</h2>
                </div>
              </div>
            </IonContent>
          </IonModal>
        </IonContent>
      </IonPage>
    );
  }
}

export default Today;

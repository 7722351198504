import {
  IonBadge,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { location, time } from "ionicons/icons";
import React from "react";
import { withRouter } from "react-router";
import ExploreContainer from "../components/ExploreContainer";
import "./Agenda.css";
import Http from "../services/http.service";
import { EventSchedule } from "../services/api.common";
import { EventCode } from "../services/api.common";
import { Storage } from "@capacitor/storage";
import _ from "underscore";

class Agenda extends React.Component<any> {
  state: any = {
    events: [],
    EventData: [],
    loading: true,
  };

  async componentDidMount() {
    this.fetchAgenda();

    let existing: any = await Storage.get({
      key: "schedule",
    });

    if (existing.value) {
      try {
        let events = JSON.parse(existing.value);

        this.setState({ events });

        this.setState({
          loading: false,
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  renderDescription(desc: any) {
    return desc;
  }

  async fetchAgenda(showLoading: boolean = false) {
    let data: any = await Storage.get({ key: "event" });

    let query = {
      Fields: ["*"],
      Filters: [
        {
          Field: "Event",
          Method: "equals",
          Value: data.value,
        },
      ],
      OrderBy: {
        Field: "Created",
        Direction: "ASC",
      },
    };

    if (showLoading) {
      this.setState({
        loading: true,
      });
    }

    await Http.search(`${EventSchedule}`, { Query: query })
      .then((response) => {
        let events = [];

        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.data &&
          response.data.data.data.length
        ) {
          events = response.data.data.data;
        }

        let eventState: any = [];

        for (let i = 0; i < events.length; i++) {
          const evt: any = events[i];

          let date = new Date(evt.Start).toDateString().substring(0, 15);

          let existingIDX = _.findIndex(eventState, { Day: date });

          if (existingIDX >= 0) {
            eventState[existingIDX].Events.push(evt);

            const sorted = eventState[existingIDX].Events.sort(
              (a: any, b: any) => {
                const bDate: any = new Date(b.Start);
                const aDate: any = new Date(a.Start);

                return aDate - bDate;
              }
            );

            eventState[existingIDX].Events = sorted;
          } else {
            let payload: any = {
              Day: date,
              Events: [],
            };

            payload.Events.push(evt);

            eventState.push(payload);
          }
        }

        // console.log(events);
        this.setState({ events: eventState });

        Storage.set({ key: "schedule", value: JSON.stringify(eventState) });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  refresh(event: CustomEvent<any>) {
    this.fetchAgenda(true).then(() => {
      console.log("refresh complete");
      event.detail.complete();
    });
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Schedule</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonRefresher slot="fixed" onIonRefresh={this.refresh.bind(this)}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Schedule</IonTitle>
            </IonToolbar>
          </IonHeader>
          {this.state.loading ? (
            <IonList>
              <IonItemGroup>
                <IonItemDivider color="light" sticky>
                  <IonLabel>
                    <IonSkeletonText
                      animated
                      style={{ height: "17px", width: "200px" }}
                    ></IonSkeletonText>
                  </IonLabel>
                </IonItemDivider>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ height: "12px", width: "140px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        animated
                        style={{ height: "18px", width: "240px" }}
                      ></IonSkeletonText>
                    </h2>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <IonSkeletonText animated></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ height: "12px", width: "140px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        animated
                        style={{ height: "18px", width: "240px" }}
                      ></IonSkeletonText>
                    </h2>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <IonSkeletonText animated></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ height: "12px", width: "140px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        animated
                        style={{ height: "18px", width: "240px" }}
                      ></IonSkeletonText>
                    </h2>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <IonSkeletonText animated></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ height: "12px", width: "140px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        animated
                        style={{ height: "18px", width: "240px" }}
                      ></IonSkeletonText>
                    </h2>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <IonSkeletonText animated></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ height: "12px", width: "140px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        animated
                        style={{ height: "18px", width: "240px" }}
                      ></IonSkeletonText>
                    </h2>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <IonSkeletonText animated></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ height: "12px", width: "140px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        animated
                        style={{ height: "18px", width: "240px" }}
                      ></IonSkeletonText>
                    </h2>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <IonSkeletonText animated></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ height: "12px", width: "140px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        animated
                        style={{ height: "18px", width: "240px" }}
                      ></IonSkeletonText>
                    </h2>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <IonSkeletonText animated></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ height: "12px", width: "140px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        animated
                        style={{ height: "18px", width: "240px" }}
                      ></IonSkeletonText>
                    </h2>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <IonSkeletonText animated></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ height: "12px", width: "140px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        animated
                        style={{ height: "18px", width: "240px" }}
                      ></IonSkeletonText>
                    </h2>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <IonSkeletonText animated></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
              </IonItemGroup>
            </IonList>
          ) : (
            <IonList>
              {this.state.events
                ? this.state.events.map((group: any, i: any) => {
                    console.log(group);

                    //console.log(date);
                    return (
                      <IonItemGroup key={i}>
                        <IonItemDivider color="light" sticky>
                          <IonLabel>{group.Day}</IonLabel>
                        </IonItemDivider>
                        {group.Events.map((ev: any, j: any) => {
                          //console.log(ev);

                          const {
                            id,
                            Name,
                            Start,
                            End,
                            Description,
                            Location,
                          } = ev;

                          // Find Time Rang of Event
                          var s = new Date(Start).toLocaleTimeString([], {
                            timeStyle: "short",
                          });
                          var e = new Date(End).toLocaleTimeString([], {
                            timeStyle: "short",
                          });
                          var TimeRangeReadable = s + "-" + e;

                          // Find Duration of Event via Start time and End time.
                          var st = new Date(Start).getTime();
                          var en = new Date(End).getTime();
                          var ms = en - st;
                          var minutes = Math.floor((ms / (1000 * 60)) % 60);
                          var hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
                          var Duration =
                            hours == 0
                              ? minutes + "min"
                              : minutes != 0
                              ? hours + "h " + minutes + "min"
                              : hours + "h";

                          return (
                            <IonItem
                              button
                              onClick={() => {
                                this.props.history.push(
                                  `/event/details/${ev.id}`
                                );
                              }}
                              key={j}
                            >
                              <IonLabel>
                                <h3
                                  style={{
                                    color: "var(--ion-color-secondary)",
                                  }}
                                >
                                  {TimeRangeReadable}
                                </h3>
                                <h2 style={{ fontWeight: "bold" }} className="">
                                  {Name}
                                </h2>
                                <p
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {ev.Summary ? ev.Summary : ""}
                                </p>
                                <div style={{ marginTop: "0.5rem" }}>
                                  <IonRow className="ion-align-items-top">
                                    <IonCol sizeXs="auto">
                                      <IonBadge
                                        color="light"
                                        style={{
                                          color: "var(--ion-color-medium)",
                                          textTransform: "lowercase",

                                          height: "22px",
                                          margin: "0px !important",
                                        }}
                                      >
                                        <IonIcon
                                          style={{
                                            position: "relative",
                                            top: "1px",
                                          }}
                                          icon={time}
                                        ></IonIcon>{" "}
                                        {Duration}
                                      </IonBadge>
                                    </IonCol>
                                    {ev.Company || ev.Location ? (
                                      <IonCol sizeXs="auto">
                                        <IonBadge
                                          color="light"
                                          style={{
                                            color: "var(--ion-color-medium)",
                                            maxWidth: "180px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            height: "22px",
                                            margin: "0px !important",
                                          }}
                                        >
                                          <IonIcon
                                            style={{
                                              position: "relative",
                                              top: "1px",
                                            }}
                                            icon={location}
                                          ></IonIcon>{" "}
                                          {ev.Company
                                            ? ev.Company.Name
                                            : ev.Location}
                                        </IonBadge>
                                      </IonCol>
                                    ) : null}
                                  </IonRow>
                                </div>
                              </IonLabel>
                            </IonItem>
                          );
                        })}
                      </IonItemGroup>
                    );
                  })
                : null}
            </IonList>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

export default withRouter(Agenda);

import './ExploreContainer.css';


const RightArrow: React.FC = () => {
  return (
    <span>→</span>
  );
};

export default RightArrow;

import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import RightArrow from "../components/RightArrow";
import "./Account.css";

const Tab3: React.FC = () => {
  return (
    <IonPage>
      <IonContent scrollY={false} scrollX={false} fullscreen>
        <div className="ion-padding" style={{ marginTop: "2rem" }}>
          <h1 style={{ fontWeight: 500, fontSize: "1.9rem" }}>
            Find some event stuff <br></br>
            that is neat
          </h1>
          <p style={{ fontSize: "1.15rem" }}>
            This is some more subtext for the welcome screen.
          </p>
        </div>
        <div
          className="ion-padding"
          style={{ position: "fixed", bottom: "1.5rem", width: "100%" }}
        >
          <IonButton
            expand="block"
            color="dark"
            href="/welcome/code"
            size="large"
          >
            Get Started
            <span style={{ paddingLeft: "0.4rem" }}>
              <RightArrow></RightArrow>
            </span>
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab3;

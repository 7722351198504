import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { withRouter } from "react-router";
import { Storage } from "@capacitor/storage";
import httpService from "../services/http.service";
import _ from "underscore";
import { EventAnnouncements, EventSchedule } from "../services/api.common";
import { location, time } from "ionicons/icons";

class UpcomingEvents extends React.Component<any> {
  state: any = {
    events: [],
    EventData: [],
  };

  async componentDidMount() {
    this.fetchAgenda();

    let existing: any = await Storage.get({
      key: "upcoming_events",
    });

    if (existing.value) {
      try {
        let events = JSON.parse(existing.value);

        this.setState({
          events,
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  renderDescription(desc: any) {
    return desc;
  }

  async fetchAgenda() {
    let data: any = await Storage.get({ key: "event" });

    let now = new Date();

    let query = {
      Fields: ["*"],
      Filters: [
        {
          Group: true,
          Filters: [
            {
              Field: "Event",
              Method: "equals",
              Value: data.value,
            },
            {
              Field: "Start",
              Method: "gte",
              Value: now.toISOString(),
            },
          ],
        },
      ],
      OrderBy: {
        Field: "Created",
        Direction: "ASC",
      },
    };

    await httpService
      .search(`${EventSchedule}`, { Query: query })
      .then((response: any) => {
        let events = [];

        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.data &&
          response.data.data.data.length
        ) {
          events = response.data.data.data;
        }

        let eventState: any = [];

        for (let i = 0; i < events.length; i++) {
          const evt: any = events[i];

          let date = new Date(evt.Start).toDateString().substring(0, 15);

          let existingIDX = _.findIndex(eventState, { Day: date });

          if (existingIDX >= 0) {
            eventState[existingIDX].Events.push(evt);

            const sorted = eventState[existingIDX].Events.sort(
              (a: any, b: any) => {
                const bDate: any = new Date(b.Start);
                const aDate: any = new Date(a.Start);

                return aDate - bDate;
              }
            );

            eventState[existingIDX].Events = sorted;
          } else {
            let payload: any = {
              Day: date,
              Events: [],
            };

            payload.Events.push(evt);

            eventState.push(payload);
          }
        }

        Storage.set({
          key: "upcoming_events",
          value: JSON.stringify(eventState),
        });

        // console.log(events);
        this.setState({ events: eventState });
      });
  }

  refresh(event: CustomEvent<any>) {
    this.fetchAgenda().then(() => {
      console.log("refresh complete");
      event.detail.complete();
    });
  }

  render() {
    return (
      <>
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Upcoming Events</IonTitle>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/event/today" />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonRefresher slot="fixed" onIonRefresh={this.refresh.bind(this)}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            <IonList>
              {this.state.events
                ? this.state.events.map((group: any, i: any) => {
                    console.log(group);

                    //console.log(date);
                    return (
                      <IonItemGroup key={i}>
                        <IonItemDivider color="light" sticky>
                          <IonLabel>{group.Day}</IonLabel>
                        </IonItemDivider>
                        {group.Events.map((ev: any, j: any) => {
                          //console.log(ev);

                          const {
                            id,
                            Name,
                            Start,
                            End,
                            Description,
                            Location,
                          } = ev;

                          // Find Time Rang of Event
                          var s = new Date(Start).toLocaleTimeString([], {
                            timeStyle: "short",
                          });
                          var e = new Date(End).toLocaleTimeString([], {
                            timeStyle: "short",
                          });
                          var TimeRangeReadable = s + "-" + e;

                          // Find Duration of Event via Start time and End time.
                          var st = new Date(Start).getTime();
                          var en = new Date(End).getTime();
                          var ms = en - st;
                          var minutes = Math.floor((ms / (1000 * 60)) % 60);
                          var hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
                          var Duration =
                            hours == 0
                              ? minutes + "min"
                              : minutes != 0
                              ? hours + "h " + minutes + "min"
                              : hours + "h";

                          return (
                            <IonItem
                              button
                              onClick={() => {
                                this.props.history.push(
                                  `/event/details/${ev.id}`
                                );
                              }}
                              key={j}
                            >
                              <IonLabel>
                                <h3
                                  style={{
                                    color: "var(--ion-color-secondary)",
                                  }}
                                >
                                  {TimeRangeReadable}
                                </h3>
                                <h2 style={{ fontWeight: "bold" }} className="">
                                  {Name}
                                </h2>
                                <p
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {ev.Summary ? ev.Summary : ""}
                                </p>
                                <IonBadge
                                  color="light"
                                  style={{
                                    marginTop: "0.5rem",
                                    marginRight: "0.5rem",
                                    color: "var(--ion-color-medium)",
                                    textTransform: "lowercase",
                                  }}
                                >
                                  <IonIcon
                                    style={{
                                      position: "relative",
                                      top: "1px",
                                    }}
                                    icon={time}
                                  ></IonIcon>{" "}
                                  {Duration}
                                </IonBadge>
                                {ev.Company || ev.Location ? (
                                  <IonBadge
                                    color="light"
                                    style={{
                                      marginTop: "0.5rem",
                                      color: "var(--ion-color-medium)",
                                      maxWidth: "180px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <IonIcon
                                      style={{
                                        position: "relative",
                                        top: "1px",
                                      }}
                                      icon={location}
                                    ></IonIcon>{" "}
                                    {ev.Company ? ev.Company.Name : ev.Location}
                                  </IonBadge>
                                ) : null}
                              </IonLabel>
                            </IonItem>
                          );
                        })}
                      </IonItemGroup>
                    );
                  })
                : null}
            </IonList>
          </IonContent>
        </IonPage>
      </>
    );
  }
}

export default withRouter(UpcomingEvents);

import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { withRouter } from "react-router";
import { Storage } from "@capacitor/storage";
import _ from "underscore";
import httpService from "../services/http.service";
import { EventSchedule } from "../services/api.common";
import Showdown from "showdown";
import {
  briefcase,
  business,
  calendar,
  calendarClear,
  location,
  star,
  time,
} from "ionicons/icons";
import moment from "moment";
import RightArrow from "../components/RightArrow";

class EventDetails extends React.Component<any> {
  state: any = {
    event: null,
    coverImageURL: "",
  };

  fetchEventByID(id: any) {
    httpService.getById(EventSchedule, id).then(
      (data) => {
        let evt = data.data.data.data;

        const { id, Name, Start, End, Description, Location } = evt;

        var start = moment(Start);
        var end = moment(End);

        let dateStartString = start.format("dddd, MMMM Do");
        let dateEndString = end.format("dddd, MMMM Do");

        let dateString = "";

        if (dateStartString == dateEndString) {
          dateString = dateStartString;
        } else {
          dateString = `${dateStartString} - ${dateEndString}`;
        }

        // Find Time Rang of Event
        var s = new Date(Start).toLocaleTimeString([], {
          timeStyle: "short",
        });
        var e = new Date(End).toLocaleTimeString([], {
          timeStyle: "short",
        });
        var TimeRangeReadable = s + "-" + e;

        // Find Duration of Event via Start time and End time.
        var st = new Date(Start).getTime();
        var en = new Date(End).getTime();
        var ms = en - st;
        var minutes = Math.floor((ms / (1000 * 60)) % 60);
        var hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
        var Duration =
          hours == 0
            ? minutes + "min"
            : minutes != 0
            ? hours + "h " + minutes + "min"
            : hours + "h";

        this.setState({
          event: evt,
          timeRange: TimeRangeReadable,
          duration: Duration,
          dateString,
        });

        if (evt.CoverImage) {
          httpService.getFileURL(evt.CoverImage).then(
            (data) => {
              this.setState({
                coverImageURL: data.data.data.url,
              });

              Storage.set({
                key: "cover_image_" + evt.id,
                value: data.data.data.url,
              });
            },
            (e) => {}
          );
        }
      },
      (e) => {
        console.error(e);

        if (e && e.response && e.response.status == 404) {
          this.props.history.replace("/event/today");

          return;
        }
      }
    );
  }

  renderHTML(event: any) {
    if (!event) {
      return "";
    }

    if (event.Description) {
      let showdown = new Showdown.Converter();

      let html = showdown.makeHtml(event.Description.RichText);

      return html;
    }

    return event.Summary ? event.Summary : "";
  }

  async componentDidMount() {
    if (
      !this.props.match ||
      !this.props.match.params ||
      !this.props.match.params.eventID
    ) {
      this.props.history.replace("/event/today");

      return;
    }

    let eventID = this.props.match.params.eventID;

    this.fetchEventByID(eventID);

    let existing = await Storage.get({ key: "schedule" });

    if (existing.value) {
      try {
        let schedule = JSON.parse(existing.value);

        if (schedule) {
          for (let i = 0; i < schedule.length; i++) {
            const day = schedule[i];

            let event = _.findWhere(day.Events, { id: eventID });

            if (event) {
              const { id, Name, Start, End, Description, Location } = event;

              var start = moment(Start);
              var end = moment(End);

              let dateStartString = start.format("dddd, MMMM Do");
              let dateEndString = end.format("dddd, MMMM Do");

              let dateString = "";

              if (dateStartString == dateEndString) {
                dateString = dateStartString;
              } else {
                dateString = `${dateStartString} - ${dateEndString}`;
              }

              // Find Time Rang of Event
              var s = new Date(Start).toLocaleTimeString([], {
                timeStyle: "short",
              });
              var e = new Date(End).toLocaleTimeString([], {
                timeStyle: "short",
              });
              var TimeRangeReadable = s + "-" + e;

              // Find Duration of Event via Start time and End time.
              var st = new Date(Start).getTime();
              var en = new Date(End).getTime();
              var ms = en - st;
              var minutes = Math.floor((ms / (1000 * 60)) % 60);
              var hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
              var Duration =
                hours == 0
                  ? minutes + "min"
                  : minutes != 0
                  ? hours + "h " + minutes + "min"
                  : hours + "h";

              this.setState({
                event,
                duration: Duration,
                timeRange: TimeRangeReadable,
                dateString,
              });

              break;
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    let coverImage = await Storage.get({ key: "cover_image_" + eventID });

    if (coverImage.value) {
      console.log(coverImage.value);

      this.setState({ coverImageURL: coverImage.value });
    }
  }

  generateMapsURL(address = "") {
    if (!address) {
      return "";
    }

    let query = address;

    if (!query) {
      return "";
    }

    let url = "https://www.google.com/maps/search/?api=1&query=";

    return url + encodeURIComponent(query);
  }

  render() {
    return (
      <>
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Event Details</IonTitle>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/event/today" />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {this.state.event ? (
              <>
                {this.state.coverImageURL ? (
                  <div
                    style={{
                      background: `url(${this.state.coverImageURL}) center center / cover`,
                      height: "300px",
                    }}
                  ></div>
                ) : null}
                <div
                  className="ion-padding"
                  style={{
                    borderTopLeftRadius: this.state.coverImageURL ? "6px" : "",
                    borderTopRightRadius: this.state.coverImageURL ? "6px" : "",
                    marginTop: this.state.coverImageURL ? "-10px" : "",
                    background: "#FFF",
                    width: "100%",
                    boxShadow: this.state.coverImageURL
                      ? "0px -10px 15px 5px #999"
                      : "",
                    minHeight: "calc(100vh - 300px - 85px)",
                  }}
                >
                  <h2 style={{ fontWeight: "bold", marginTop: "5px" }}>
                    {this.state.event.Name}
                  </h2>
                  <div
                    style={{
                      color: "var(--ion-color-medium)",
                      marginBottom: "30px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: this.renderHTML(this.state.event),
                    }}
                  ></div>
                  <div>
                    <ul
                      style={{
                        listStyleType: "none",
                        marginLeft: "20px",
                        paddingLeft: "0px",
                        borderLeft: "5px solid var(--ion-color-light)",
                      }}
                    >
                      <li
                        style={{
                          position: "relative",
                          left: "-22px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <IonRow className="ion-align-items-center">
                          <IonCol sizeXs="auto">
                            <div
                              style={{
                                padding: "5px",
                                borderRadius: "100%",
                                background: "var(--ion-color-light)",
                                height: "30px",
                                width: "30px",
                                textAlign: "center",
                                color: "var(--ion-color-medium)",
                                marginRight: "10px",
                                border: "1px solid #DDD",
                              }}
                            >
                              <IonIcon
                                icon={calendarClear}
                                style={{ position: "relative", top: "1px" }}
                              ></IonIcon>
                            </div>
                          </IonCol>
                          <IonCol style={{ color: "var(--ion-color-dark)" }}>
                            {this.state.dateString}
                          </IonCol>
                        </IonRow>
                      </li>
                      <li
                        style={{
                          position: "relative",
                          left: "-22px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <IonRow className="ion-align-items-center">
                          <IonCol sizeXs="auto">
                            <div
                              style={{
                                padding: "5px",
                                borderRadius: "100%",
                                background: "var(--ion-color-light)",
                                height: "30px",
                                width: "30px",
                                textAlign: "center",
                                color: "var(--ion-color-medium)",
                                marginRight: "10px",
                                border: "1px solid #DDD",
                              }}
                            >
                              <IonIcon
                                icon={time}
                                style={{ position: "relative", top: "1px" }}
                              ></IonIcon>
                            </div>
                          </IonCol>
                          <IonCol style={{ color: "var(--ion-color-dark)" }}>
                            {this.state.timeRange}
                          </IonCol>
                        </IonRow>
                      </li>
                      {this.state.event.Company ? (
                        <li
                          style={{
                            position: "relative",
                            left: "-22px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          <IonRow className="ion-align-items-center">
                            <IonCol sizeXs="auto">
                              <div
                                style={{
                                  padding: "5px",
                                  borderRadius: "100%",
                                  background: "var(--ion-color-light)",
                                  height: "30px",
                                  width: "30px",
                                  textAlign: "center",
                                  color: "var(--ion-color-medium)",
                                  marginRight: "10px",
                                  border: "1px solid #DDD",
                                }}
                              >
                                <IonIcon
                                  icon={briefcase}
                                  style={{ position: "relative", top: "1px" }}
                                ></IonIcon>
                              </div>
                            </IonCol>
                            <IonCol style={{ color: "var(--ion-color-dark)" }}>
                              Hosted by {this.state.event.Company.Name}
                            </IonCol>
                          </IonRow>
                          <IonRow
                            className="ion-align-items-center"
                            style={{ marginTop: "-10px" }}
                          >
                            <IonCol sizeXs="auto">
                              <div
                                style={{
                                  height: "30px",
                                  width: "30px",
                                }}
                              ></div>
                            </IonCol>
                            <IonCol
                              style={{
                                color: "var(--ion-color-dark)",
                              }}
                            >
                              <div
                                style={{
                                  paddingLeft: "10px",
                                  paddingTop: "5px",
                                }}
                              >
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.props.history.push(
                                      `/event/companies/${this.state.event.Company.id}`
                                    );
                                  }}
                                >
                                  <IonButton size="small" color="light">
                                    View Company&nbsp;&nbsp;
                                    <RightArrow></RightArrow>
                                  </IonButton>
                                </a>
                              </div>
                            </IonCol>
                          </IonRow>
                        </li>
                      ) : null}
                      {this.state.event.Location ? (
                        <li
                          style={{
                            position: "relative",
                            left: "-22px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          <IonRow className="ion-align-items-center">
                            <IonCol sizeXs="auto">
                              <div
                                style={{
                                  padding: "5px",
                                  borderRadius: "100%",
                                  background: "var(--ion-color-light)",
                                  height: "30px",
                                  width: "30px",
                                  textAlign: "center",
                                  color: "var(--ion-color-medium)",
                                  marginRight: "10px",
                                  border: "1px solid #DDD",
                                }}
                              >
                                <IonIcon
                                  icon={location}
                                  style={{ position: "relative", top: "1px" }}
                                ></IonIcon>
                              </div>
                            </IonCol>
                            <IonCol style={{ color: "var(--ion-color-dark)" }}>
                              {this.state.event.Location}
                            </IonCol>
                          </IonRow>

                          {this.state.event.GoogleAddress ? (
                            <IonRow
                              className="ion-align-items-center"
                              style={{ marginTop: "-10px" }}
                            >
                              <IonCol sizeXs="auto">
                                <div
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                  }}
                                ></div>
                              </IonCol>
                              <IonCol
                                style={{
                                  color: "var(--ion-color-dark)",
                                }}
                              >
                                <div
                                  style={{
                                    paddingLeft: "10px",
                                    paddingTop: "5px",
                                  }}
                                >
                                  <a
                                    href={this.generateMapsURL(
                                      this.state.event.GoogleAddress
                                    )}
                                  >
                                    <IonButton size="small" color="light">
                                      Directions&nbsp;&nbsp;
                                      <RightArrow></RightArrow>
                                    </IonButton>
                                  </a>
                                </div>
                              </IonCol>
                            </IonRow>
                          ) : null}
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </>
            ) : null}
          </IonContent>
        </IonPage>
      </>
    );
  }
}

export default withRouter(EventDetails);

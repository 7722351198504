import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonSlides,
  IonSlide,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonBadge,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText,
} from "@ionic/react";
import { chevronForward, trophy } from "ionicons/icons";
import React from "react";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import httpService from "../services/http.service";
import { EventCode, EventSponsors } from "../services/api.common";
import { Storage } from "@capacitor/storage";
import { withRouter } from "react-router";
import _ from "underscore";

class EventPassportListView extends React.Component<any> {
  state: any = {
    companies: [],
    sponsors: [],
    loading: true,
  };

  async componentDidMount() {
    this.getEventSponsors();

    let sponsors: any = await Storage.get({ key: "event_sponsors" });

    if (sponsors.value) {
      try {
        sponsors = JSON.parse(sponsors.value);

        this.setState({
          loading: false,
        });

        this.setState({
          sponsors,
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  generateIndustryString(company: any) {
    if (!company) {
      return "";
    }

    if (company.Industry) {
      let out = company.Industry;

      if (company.SecondaryIndustry) {
        out += ", " + company.SecondaryIndustry;
      }

      return out;
    } else if (company.SecondaryIndustry) {
      return company.SecondaryIndustry;
    }

    return "";
  }

  refresh(event: CustomEvent<any>) {
    this.getEventSponsors(true).then(() => {
      console.log("refresh complete");
      event.detail.complete();
    });
  }

  async getEventSponsors(showLoading = false) {
    return new Promise(async (resolve) => {
      let event = await Storage.get({ key: "event" });

      let query = {
        Query: {
          Fields: ["*"],
          Filters: [
            {
              Field: "Event",
              Method: "equals",
              Value: event.value,
            },
          ],
        },
      };

      if (showLoading) {
        this.setState({
          loading: true,
        });
      }

      httpService
        .update(EventSponsors + "/search", query)
        .then(
          (data) => {
            let records = data.data.data.data;

            let sponsors = [];

            for (let i = 0; i < records.length; i++) {
              if (records[i].SponsorCompany) {
                sponsors.push(records[i].SponsorCompany);
              }
            }

            this.setState({ sponsors });

            Storage.set({
              key: "event_sponsors",
              value: JSON.stringify(sponsors),
            });
          },
          (e: any) => {}
        )
        .finally(() => {
          resolve(true);

          this.setState({
            loading: false,
          });
        });
    });
  }

  isSponsor(company: any) {
    if (!this.state.sponsors || !this.state.sponsors.length) {
      return false;
    }

    if (!company) {
      return false;
    }

    return _.findWhere(this.state.sponsors, { id: company.id }) ? true : false;
  }

  render() {
    return (
      <>
        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={this.refresh.bind(this)}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          {this.state.loading ? (
            <>
              <IonList>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ width: "100px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        style={{ width: "200px", height: "19px" }}
                        animated
                      ></IonSkeletonText>
                    </h2>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ width: "100px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        style={{ width: "200px", height: "19px" }}
                        animated
                      ></IonSkeletonText>
                    </h2>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ width: "100px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        style={{ width: "200px", height: "19px" }}
                        animated
                      ></IonSkeletonText>
                    </h2>
                  </IonLabel>
                </IonItem>
              </IonList>
            </>
          ) : (
            <>
              <IonList>
                {/*this.state.sponsors
                  ? this.state.sponsors.map((company: any, i: any) => {
                      //console.log(company);

                      //console.log(date);
                      return (
                        <IonItem
                          key={i}
                          button
                          onClick={() => {
                            this.props.history.push(
                              `/event/companies/${company.id}`
                            );
                          }}
                        >
                          <IonLabel>
                            <h3
                              style={{
                                color: "var(--ion-color-secondary)",
                              }}
                            >
                              {this.generateIndustryString(company)}
                            </h3>
                            <h2 style={{ fontWeight: "bold" }} className="">
                              {company.Name}
                            </h2>
                            {company.Recruiting ? (
                              <IonBadge
                                color="primary"
                                style={{
                                  marginTop: "0.5rem",
                                  marginRight: "0.5rem",
                                }}
                              >
                                {" "}
                                We're Hiring!
                              </IonBadge>
                            ) : null}
                          </IonLabel>
                        </IonItem>
                      );
                    })
                : null*/}
                <IonItem>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      No Passport Entries
                    </h3>
                    <h2
                      style={{ fontWeight: "bold" }}
                      className="ion-text-wrap"
                    >
                      Check back here after you check into an event to see what
                      you've attended.
                    </h2>
                  </IonLabel>
                </IonItem>
              </IonList>
            </>
          )}
        </IonContent>
      </>
    );
  }
}

export default withRouter(EventPassportListView);

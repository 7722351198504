import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonSlides,
  IonSlide,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonBadge,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText,
} from "@ionic/react";
import { chevronForward, trophy } from "ionicons/icons";
import React from "react";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import httpService from "../services/http.service";
import { EventCode, EventSponsors } from "../services/api.common";
import { Storage } from "@capacitor/storage";
import { withRouter } from "react-router";
import _ from "underscore";

class CompanyListView extends React.Component<any> {
  state: any = {
    companies: [],
    sponsors: [],
    loading: true,
  };

  async componentDidMount() {
    this.getCompanies();
    this.getEventSponsors();

    let companies: any = await Storage.get({ key: "companies" });
    let sponsors: any = await Storage.get({ key: "event_sponsors" });

    if (companies.value) {
      try {
        companies = JSON.parse(companies.value);

        companies = _.sortBy(companies, "Name");

        this.setState({
          loading: false,
        });

        this.setState({
          companies,
        });
      } catch (e) {
        console.error(e);
      }
    }

    if (sponsors.value) {
      try {
        sponsors = JSON.parse(sponsors.value);

        this.setState({
          sponsors,
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  generateIndustryString(company: any) {
    if (!company) {
      return "";
    }

    if (!company.Industry && !company.SecondIndustry) {
      return "";
    }

    if (company.Industry) {
      let out = company.Industry;

      if (company.SecondaryIndustry) {
        out += ", " + company.SecondaryIndustry;
      }

      return out;
    } else if (company.SecondaryIndustry) {
      return company.SecondaryIndustry;
    }

    return "";
  }

  refresh(event: CustomEvent<any>) {
    //console.log("refresh");

    this.getEventSponsors();
    this.getCompanies(true).then(() => {
      console.log("refresh complete");
      event.detail.complete();
    });
  }

  async getCompanies(showLoading = false) {
    return new Promise(async (resolve) => {
      let event = await Storage.get({ key: "event" });

      if (showLoading) {
        this.setState({
          loading: true,
        });
      }

      httpService
        .getById(EventCode, event.value)
        .then(
          (data) => {
            let ev = data.data.data.data;

            //console.log(ev);

            // let companies = ev.ParticipatingCompanies
            //   ? ev.ParticipatingCompanies
            //   : [];

            let companies = ev.Companies
              ? ev.Companies
              : [];

            companies = _.sortBy(companies, "Name");

            this.setState({ companies });

            Storage.set({ key: "companies", value: JSON.stringify(companies) });

            try {
              Storage.set({ key: "event_object", value: JSON.stringify(ev) });
            } catch (e: any) {
              console.error(e);
            }
          },
          (e: any) => {}
        )
        .finally(() => {
          resolve(true);

          this.setState({
            loading: false,
          });
        });
    });
  }

  async getEventSponsors() {
    let event = await Storage.get({ key: "event" });

    let query = {
      Query: {
        Fields: ["*"],
        Filters: [
          {
            Field: "Event",
            Method: "equals",
            Value: event.value,
          },
        ],
      },
    };

    httpService.update(EventSponsors + "/search", query).then(
      (data) => {
        let records = data.data.data.data;

        let sponsors = [];

        for (let i = 0; i < records.length; i++) {
          if (records[i].SponsorCompany) {
            sponsors.push({
              company: records[i].SponsorCompany,
              record: records[i],
            });
          }
        }

        this.setState({ sponsors });

        Storage.set({ key: "event_sponsors", value: JSON.stringify(sponsors) });
      },
      (e: any) => {}
    );
  }

  isSponsor(company: any) {
    if (!this.state.sponsors || !this.state.sponsors.length) {
      return false;
    }

    if (!company) {
      return false;
    }

    let find = _.find(this.state.sponsors, (sponsor: any) => {
      return sponsor?.company?.id == company?.id;
    });

    return find ? find : false;
  }

  generateSponsorString(level: any) {
    return level;
  }

  render() {
    return (
      <>
        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={this.refresh.bind(this)}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          {this.state.loading ? (
            <>
              <IonList>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ width: "100px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        style={{ width: "200px", height: "19px" }}
                        animated
                      ></IonSkeletonText>
                    </h2>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ width: "100px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        style={{ width: "200px", height: "19px" }}
                        animated
                      ></IonSkeletonText>
                    </h2>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ width: "100px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        style={{ width: "200px", height: "19px" }}
                        animated
                      ></IonSkeletonText>
                    </h2>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ width: "100px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        style={{ width: "200px", height: "19px" }}
                        animated
                      ></IonSkeletonText>
                    </h2>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ width: "100px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        style={{ width: "200px", height: "19px" }}
                        animated
                      ></IonSkeletonText>
                    </h2>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ width: "100px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        style={{ width: "200px", height: "19px" }}
                        animated
                      ></IonSkeletonText>
                    </h2>
                  </IonLabel>
                </IonItem>
                <IonItem button>
                  <IonLabel>
                    <h3
                      style={{
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      <IonSkeletonText
                        animated
                        style={{ width: "100px" }}
                      ></IonSkeletonText>
                    </h3>
                    <h2 style={{ fontWeight: "bold" }} className="">
                      <IonSkeletonText
                        style={{ width: "200px", height: "18px" }}
                        animated
                      ></IonSkeletonText>
                    </h2>
                  </IonLabel>
                </IonItem>
              </IonList>
            </>
          ) : (
            <>
              <IonList>
                {this.state.companies
                  ? this.state.companies.map((company: any, i: any) => {
                      //console.log(company);

                      //console.log(date);
                      return (
                        <>
                          {company ? (
                            <IonItem
                              key={i}
                              button
                              onClick={() => {
                                this.props.history.push(
                                  // `/event/companies/${company.id}`
                                  `/event/companies/${company.Company.id}`
                                );
                              }}
                            >
                              <IonLabel>
                                <h3
                                  style={{
                                    color: "var(--ion-color-secondary)",
                                  }}
                                >
                                  {this.generateIndustryString(company.Company)}
                                </h3>
                                <h2 style={{ fontWeight: "bold" }} className="">
                                  {/* {company ? company._RecordName : ""} */
                                  company ? company.Company._RecordName : ""
                                  }
                                </h2>
                                {company.Recruiting ? (
                                  <IonBadge
                                    color="primary"
                                    style={{
                                      marginTop: "0.5rem",
                                      marginRight: "0.5rem",
                                    }}
                                  >
                                    {" "}
                                    We're Hiring!
                                  </IonBadge>
                                ) : null}
                                {this.isSponsor(company.Company) ? (
                                  <IonBadge
                                    color="light"
                                    style={{
                                      marginTop: "0.5rem",
                                      marginRight: "0.5rem",
                                      color: "var(--ion-color-medium)",
                                    }}
                                  >
                                    {this.generateSponsorString(
                                      this.isSponsor(company.Company).record
                                        .SponsorshipLevel
                                    )}
                                  </IonBadge>
                                ) : null}
                              </IonLabel>
                            </IonItem>
                          ) : null}
                        </>
                      );
                    })
                  : null}
              </IonList>
            </>
          )}
        </IonContent>
      </>
    );
  }
}

export default withRouter(CompanyListView);

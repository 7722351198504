import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonSlides,
  IonSlide,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
} from "@ionic/react";
import {
  chevronForward,
  logoFacebook,
  logoInstagram,
  logoLinkedin,
  logoTwitch,
  logoTwitter,
} from "ionicons/icons";
import React from "react";

import { Storage } from "@capacitor/storage";

class SocialMediaWidget extends React.Component {
  state: any = {
    hashtag: "",
  };

  openSocialProfile(url: string = "") {
    if (Capacitor.isNativePlatform()) {
      Browser.open({ url, presentationStyle: "popover" });

      return;
    }

    window.open(url, "_blank")?.focus();
  }

  async loadHashtag() {
    let event: any = await Storage.get({ key: "event_object" });

    if (event.value) {
      try {
        event = JSON.parse(event.value);

        this.setState({
          hashtag: event.Hashtag ? event.Hashtag : "",
          facebook: event.FacebookURL?.length>0 ? event.FacebookURL[0].URL : "",
          instagram: event.InstagramURL?.length>0 ? event.InstagramURL[0].URL : "",
          twitter: event.TwitterURL?.length>0 ? event.TwitterURL[0].URL : "",
          linkedin: event.LinkedInURL?.length>0 ? event.LinkedInURL[0].URL : "",
        });
      } catch (e: any) {
        console.log(e);
      }
    }
  }

  componentDidMount() {
    this.loadHashtag();
  }

  render() {
    return (
      <>
        <div
          className="ion-padding ion-text-uppercase ion-color-medium"
          style={{ fontWeight: "bold" }}
        >
          <IonGrid style={{ padding: 0 }}>
            <IonRow>
              <IonCol>TAG US USING {this.state.hashtag}</IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <IonCard button={false} style={{ marginTop: 0 }}>
          <IonCardHeader className="ion-text-center">
            <IonRow>
              {this.state.facebook ? (
                <IonCol
                  style={{ borderRight: "1px solid var(--ion-color-light)" }}
                  onClick={() => this.openSocialProfile(this.state.facebook)}
                >
                  <IonIcon
                    color="secondary"
                    icon={logoFacebook}
                    size="large"
                  ></IonIcon>
                </IonCol>
              ) : null}
              {this.state.linkedin ? (
                <IonCol
                  style={{ borderRight: "1px solid var(--ion-color-light)" }}
                  onClick={() => this.openSocialProfile(this.state.linkedin)}
                >
                  <IonIcon
                    color="secondary"
                    icon={logoLinkedin}
                    size="large"
                  ></IonIcon>
                </IonCol>
              ) : null}
              {this.state.instagram ? (
                <IonCol
                  onClick={() => this.openSocialProfile(this.state.instagram)}
                >
                  <IonIcon
                    color="secondary"
                    icon={logoInstagram}
                    size="large"
                  ></IonIcon>
                </IonCol>
              ) : null}
              {this.state.twitter ? (
                <IonCol
                  onClick={() => this.openSocialProfile(this.state.twitter)}
                  style={{ borderLeft: "1px solid var(--ion-color-light)" }}
                >
                  <IonIcon
                    color="secondary"
                    icon={logoTwitter}
                    size="large"
                  ></IonIcon>
                </IonCol>
              ) : null}
            </IonRow>
          </IonCardHeader>
        </IonCard>
      </>
    );
  }
}

export default SocialMediaWidget;
